class Physics {
    
    getCategories() {
        const titleString = "Physics"
        var namesList = [];
        var linksList = [];
        var itemsList = [];

        linksList.push("/physics/electricity-and-magnetismus");
        namesList.push("Electricity and magnetism");

        linksList.push("/physics/hydromechanics");
        namesList.push("Hydromechanics");

        linksList.push("/physics/mechanical-vibration-waves-acoustic");
        namesList.push("Mechanical vibration, waves and acoustic");

        linksList.push("/physics/mechanics");
        namesList.push("Mechanics");

        linksList.push("/physics/molecular-physics-thermodynamic");
        namesList.push("Molecular physics and thermodynamic");

        linksList.push("/physics/optics");
        namesList.push("Optics");

        linksList.push("/physics/quantum-physics");
        namesList.push("Quantum physics");

        linksList.push("/physics/special-theory-of-relativity");
        namesList.push("The special theory of relativity");

        linksList.map((linkT, index) => {
            var item = {
                link: linkT,
                name: namesList[index]
            }

            itemsList.push(item);
        });

        return {
            title: titleString,
            items: itemsList
        }
    }
}

export default new Physics();
