import Strings from "./strings.helper";

class Optics {
    getFormulas() {
        const titleString = "Optics";
        var namesList = [];
        var linksList = [];
        var itemsList = [];

        linksList.push("/physics/optics/refractive-index");
        namesList.push("Refractive index");

        linksList.push("/physics/optics/law-of-reflection");
        namesList.push("Law of reflection");

        linksList.push("/physics/optics/the-mirror-equation-concave-mirrors");
        namesList.push("The mirror equation - concave mirrors");

        linksList.push("/physics/optics/the-mirror-equation-lens");
        namesList.push("The mirror equation - lens");

        linksList.push("/physics/optics/focal-length-of-mirror");
        namesList.push("Focal length of mirror");

        linksList.push("/physics/optics/linear-magnification");
        namesList.push("Linear magnification");

        linksList.push("/physics/optics/angular-magnification");
        namesList.push("Angular magnification");

        linksList.push("/physics/optics/magnification-of-mirror-lens1");
        namesList.push("Magnification of mirror, lens 1");

        linksList.push("/physics/optics/magnification-of-mirror-lens2");
        namesList.push("Magnification of mirror, lens 2");

        linksList.push("/physics/optics/angular-magnifivation-of-magnifier");
        namesList.push("Angular magnification of magnifier");

        linksList.push("/physics/optics/angular-magnification-of-microscope");
        namesList.push("Angular magnification of microscope");

        linksList.push("/physics/optics/angular-magnification-of-telescope");
        namesList.push("Angular magnification of telescope");

        linksList.push("/physics/optics/wavelength-in-vacuum");
        namesList.push("Wavelength in vacuum");

        linksList.push("/physics/optics/optical-path");
        namesList.push("Optical path");

        linksList.push("/physics/optics/luminosity");
        namesList.push("Luminosity");

        linksList.push("/physics/optics/iluminance1");
        namesList.push("Illuminance 1");

        linksList.push("/physics/optics/illuminance2");
        namesList.push("Illuminance 2");

        linksList.push("/physics/optics/wiens-displacement-law");
        namesList.push("Wien's displacement law");

        linksList.push("/physics/optics/stefan-boltzman-law");
        namesList.push("Stefan–Boltzmann law");

        linksList.map((linkT, index) => {
            var item = {
                link: linkT,
                name: namesList[index]
            }

            itemsList.push(item);
        });

        return {
            title: titleString,
            items: itemsList
        }
    }

    refractive_index() {
        return {
            calculatorType: "e1Ee2De3",
            title: Strings.refractive_index(),
            description: Strings.refractive_index_description(),
            element1: "n",
            baseUnit1: " ",
            element2: "c",
            baseUnit2: "m/s",
            element3: "v",
            baseUnit3: "m/s",
            imagePath: "refrative_index",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    getFormulaParmas(formulaName) {
        switch (formulaName) {
            case "refractive-index":
               return this.refractive_index();
            case "law-of-reflection":
               return this.law_of_reflection();
            case "the-mirror-equation-concave-mirrors":
               return this.the_mirror_equation_concave_mirrors();
            case "the-mirror-equation-lens":
               return this.the_mirror_equation_lens();
            case "focal-length-of-mirror":
               return this.focal_length_of_mirror();
            case "linear-magnification":
               return this.linear_magnification();
            case "angular-magnification":
               return this.angular_magnification();
            case "magnification-of-mirror-lens1":
               return this.magnification_of_mirror_lens1();
            case "magnification-of-mirror-lens2":
               return this.magnification_of_mirror_lens2();
            case "angular-magnifivation-of-magnifier":
               return this.angular_magnifivation_of_magnifier();
            case "angular-magnification-of-microscope":
               return this.angular_magnification_of_microscope();
            case "angular-magnification-of-telescope":
               return this.angular_magnification_of_telescope();
            case "wavelength-in-vacuum":
               return this.wavelength_in_vacuum();
            case "optical-path":
               return this.optical_path();
            case "luminosity":
               return this.luminosity();
            case "iluminance1":
               return this.iluminance1();
            case "illuminance2":
               return this.iluminance2();
            case "wiens-displacement-law":
               return this.wiens_displacement_law();
            case "stefan-boltzman-law":
               return this.stefan_boltzman_law();
        }
    }

    law_of_reflection() {
        return {
            calculatorType: "e1Ee2",
            title: Strings.law_of_reflection(),
            description: Strings.law_of_reflection_description(),
            element1: "α",
            baseUnit1: "°",
            element2: "α'",
            baseUnit2: "°",
            imagePath: "law_of_reglection",
            element3: null,
            baseUnit3: null,
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    critical_angle() {
        return {
            calculatorType: "Sine1Ee2De3",
            title: Strings.critical_angle(),
            description: Strings.critical_angle_description(),
            element1: "α",
            baseUnit1: "°",
            element2: "n2",
            baseUnit2: " ",
            element3: "n1",
            baseUnit3: " ",
            imagePath: "critical_angle",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    the_mirror_equation_concave_mirrors() {
        return {
            calculatorType: "_1de1A1de2E2de3",
            title: Strings.the_mirror_equation_concave_mirrors(),
            description: Strings.the_mirror_equation_concave_mirrors_description(),
            element1: "a",
            baseUnit1: "m",
            element2: "a'",
            baseUnit2: "m",
            element3: "r",
            baseUnit3: "m",
            imagePath: "the_mirror_equation_concave_mirrors",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    the_mirror_equation_lens() {
        return {
            calculatorType: "_1de1A1de2E1de3",
            title: Strings.the_mirror_equation_lens(),
            description: Strings.the_mirror_equation_lens_description(),
            element1: "a",
            baseUnit1: "m",
            element2: "a'",
            baseUnit2: "m",
            element3: "f",
            baseUnit3: "m",
            imagePath: "the_mirror_equation_lens",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    focal_length_of_mirror() {
        return {
            calculatorType: "e1Ee2D2",
            title: Strings.focal_length_of_mirror(),
            description: Strings.focal_length_of_mirror_description(),
            element1: "f",
            baseUnit1: "m",
            element2: "r",
            baseUnit2: "m",
            imagePath: "focal_length",
            element3: null,
            baseUnit3: null,
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    optical_power() {
        return {
            calculatorType: "e1Ee2D2",
            title: Strings.optical_power(),
            description: Strings.optical_power_description(),
            element1: Strings.optical_power_(),
            baseUnit1: "m^-1",
            element2: "r",
            baseUnit2: "m",
            imagePath: "optical_power",
            element3: null,
            baseUnit3: null,
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    linear_magnification() {
        return {
            calculatorType: "e1Ee2De3",
            title: Strings.linear_magnification(),
            description: Strings.linear_magnification_description(),
            element1: "Z",
            baseUnit1: " ",
            element2: "y'",
            baseUnit2: "m",
            element3: "y",
            baseUnit3: "m",
            imagePath: "linear_magnification",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    angular_magnification() {
        return {
            calculatorType: "e1Ee2De3",
            title: Strings.angular_magnification(),
            description: Strings.angular_magnification_description(),
            element1: "γ",
            baseUnit1: " ",
            element2: "τ'",
            baseUnit2: "m",
            element3: "τ",
            baseUnit3: "m",
            imagePath: "angular_magnification",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    magnification_of_mirror_lens1() {
        return {
            calculatorType: "e1EMe2De3",
            title: Strings.magnification_of_mirror_lens1(),
            description: Strings.magnification_of_mirror_lens1_description(),
            element1: "Z",
            baseUnit1: " ",
            element2: "a'",
            baseUnit2: "m",
            element3: "a",
            baseUnit3: "m",
            imagePath: "magnification_of_mirror_lens1",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    magnification_of_mirror_lens2() {
        return {
            calculatorType: "e1EMe2se3De3",
            title: Strings.magnification_of_mirror_lens2(),
            description: Strings.magnification_of_mirror_lens2_description(),
            element1: "Z",
            baseUnit1: " ",
            element2: "a'",
            baseUnit2: "m",
            element3: "f",
            baseUnit3: "m",
            imagePath: "magnification_of_mirror_lens2",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    angular_magnifivation_of_magnifier() {
        return {
            calculatorType: "e1Ee2De3",
            title: Strings.angular_magnification_of_magnifier(),
            description: Strings.angular_magnification_of_magnifier_description(),
            element1: "γ",
            baseUnit1: " ",
            element2: "d",
            baseUnit2: "m",
            element3: "a",
            baseUnit3: "m",
            imagePath: "angular_magnifivation_of_magnifier",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    angular_magnification_of_microscope() {
        return {
            calculatorType: "e1EdeltaDe2Me3de4",
            title: Strings.angular_magnification_of_microscope(),
            description: Strings.angular_magnification_of_microscope_description(),
            element1: "γ",
            baseUnit1: " ",
            element2: "Δ",
            baseUnit2: "m",
            element3: "f1",
            baseUnit3: "m",
            element4: "d",
            baseUnit4: "m",
            element5: "f2",
            baseUnit5: "m",
            imagePath: "angular_magnifivation_of_microscope",
            baseUnit6: null,
            element6: null
        }
    }

    angular_magnification_of_telescope() {
        return {
            calculatorType: "e1Ee2De3",
            title: Strings.angular_magnification_of_telescope(),
            description: Strings.angular_magnification_of_telescope_description(),
            element1: "γ",
            baseUnit1: " ",
            element2: "f1",
            baseUnit2: "m",
            element3: "f2",
            baseUnit3: "m",
            imagePath: "angular_magnifivation_of_telescope",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    wavelength_in_vacuum() {
        return {
            calculatorType: "e1Ee2e3",
            title: Strings.wavelength_in_vacuum(),
            description: Strings.wavelength_in_vacuum_description(),
            element1: "λ0",
            baseUnit1: "m",
            element2: "λ",
            baseUnit2: "m",
            element3: "n",
            baseUnit3: " ",
            imagePath: "wavelength_in_vacuum",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    optical_path() {
        return {
            calculatorType: "e1Ee2e3",
            title: Strings.optical_path(),
            description: Strings.optical_path_description(),
            element1: "l",
            baseUnit1: "m",
            element2: "n",
            baseUnit2: " ",
            element3: "s",
            baseUnit3: "m",
            imagePath: "optical_path",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    luminosity() {
        return {
            calculatorType: "e1Ee2De3",
            title: Strings.luminosity(),
            description: Strings.luminosity_description(),
            element1: "I",
            baseUnit1: "cd",
            element2: "ΔΦ",
            baseUnit2: "lm",
            element3: "ΔΩ",
            baseUnit3: "sr",
            imagePath: "luminosity",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    iluminance1() {
        return {
            calculatorType: "e1Ee2De3",
            title: Strings.illuminance1(),
            description: Strings.illuminance1_description(),
            element1: "E",
            baseUnit1: "lx",
            element2: "ΔΦ",
            baseUnit2: "lm",
            element3: "ΔS",
            baseUnit3: "m^2",
            imagePath: "iluminance1",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    illuminance2() {
        return {
            calculatorType: "e1Ee2de3Mcose4",
            title: Strings.illuminance2(),
            description: Strings.illuminance2_description(),
            element1: "E",
            baseUnit1: "lx",
            element2: "I",
            baseUnit2: "A",
            element3: "r",
            baseUnit3: "m",
            element4: "α",
            baseUnit4: "°",
            imagePath: "iluminance2",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    wiens_displacement_law() {
        return {
            calculatorType: "e1e2Ee3",
            title: Strings.wiens_displacement_law(),
            description: Strings.wiens_displacement_law_description(),
            element1: "λ",
            baseUnit1: "m",
            element2: "T",
            baseUnit2: "K",
            element3: "b",
            baseUnit3: "mmK",
            imagePath: "wien_displacement_law",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    stefan_boltzman_law() {
        return {
            calculatorType: "e1Ee2e3P4",
            title: Strings.Stefan_Boltzmann_law(),
            description: Strings.Stefan_Boltzmann_law_description(),
            element1: "I",
            baseUnit1: "W/m^2",
            element2: "σ",
            baseUnit2: "Wm^-2K^-4",
            element3: "T",
            baseUnit3: "K",
            imagePath: "stefan_boltzman_law",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }
}


export default new Optics();
