import http from "./http-common-reporting";
import { v4 as uuidv4 } from "uuid";

let visitorId;

class ReportVisitService {
  report(path, title) {
    if (!visitorId) {
      visitorId = uuidv4();
    }

    const data = {
      id: "0ac3014e-085a-4c3f-9d2e-273c0975f179",
      path: path,
      pageName: title,
      visitorId: visitorId,
    };
    http.post("/", data);
  }
}

export default new ReportVisitService();
