import React, { Component } from "react";

import chemistryHelper from "../helpers/chemistry.helper";
import electricityAndMagnetismusPhysicsHelper from "../helpers/electricity-and-magnetismus.physics.helper";
import hydromechanicsPhysicsHelper from "../helpers/hydromechanics.physics.helper";
import mechanicalVibrationWavesAcousticPhysicsHelper from "../helpers/mechanical-vibration-waves-acoustic.physics.helper";
import mechanicsPhysicsHelper from "../helpers/mechanics.physics.helper";
import molecularPhysicsThermodynamicPhysicsHelper from "../helpers/molecular-physics-thermodynamic.physics.helper";
import opticsPhysicsHelper from "../helpers/optics.physics.helper";
import quantumPhysicsPhysicsHelper from "../helpers/quantum-physics.physics.helper";
import specialTheoryOfRelativityPhysicsHelper from "../helpers/special-theory-of-relativity.physics.helper";
import Units from "../helpers/units.helper";
import reportVisitService from "../reporting-visit/report-visit.service";
import SolverService from "../services/solve.service";
import Loader from "./loader.component";

export default class Solver extends Component {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.parseResponse = this.parseResponse.bind(this);
    this.solveFormula = this.solveFormula.bind(this);
    this.renderUnits = this.renderUnits.bind(this);
    this.onInputValueElement1Change =
      this.onInputValueElement1Change.bind(this);
    this.onInputValueElement2Change =
      this.onInputValueElement2Change.bind(this);
    this.onInputValueElement3Change =
      this.onInputValueElement3Change.bind(this);
    this.onInputValueElement4Change =
      this.onInputValueElement4Change.bind(this);
    this.onInputValueElement5Change =
      this.onInputValueElement5Change.bind(this);
    this.onInputValueElement6Change =
      this.onInputValueElement6Change.bind(this);
    this.onUnitPositionElement1Change =
      this.onUnitPositionElement1Change.bind(this);
    this.onUnitPositionElement2Change =
      this.onUnitPositionElement2Change.bind(this);
    this.onUnitPositionElement3Change =
      this.onUnitPositionElement3Change.bind(this);
    this.onUnitPositionElement4Change =
      this.onUnitPositionElement4Change.bind(this);
    this.onUnitPositionElement5Change =
      this.onUnitPositionElement5Change.bind(this);
    this.onUnitPositionElement6Change =
      this.onUnitPositionElement6Change.bind(this);
    this.onSelectElement1Change = this.onSelectElement1Change.bind(this);
    this.onSelectElement2Change = this.onSelectElement2Change.bind(this);
    this.onSelectElement3Change = this.onSelectElement3Change.bind(this);
    this.onSelectElement4Change = this.onSelectElement4Change.bind(this);
    this.onSelectElement5Change = this.onSelectElement5Change.bind(this);
    this.onSelectElement6Change = this.onSelectElement6Change.bind(this);
    this.autoHeight = this.autoHeight.bind(this);
    this.onFocusInput = this.onFocusInput.bind(this);

    this.state = {
      title: "",
      description: "",
      imagePath: "",
      inputValueElement1: 0,
      inputValueElement2: 0,
      inputValueElement3: 0,
      inputValueElement4: 0,
      inputValueElement5: 0,
      inputValueElement6: 0,
      unitTypeElement1: "",
      unitTypeElement2: "",
      unitTypeElement3: "",
      unitTypeElement4: "",
      unitTypeElement5: "",
      unitTypeElement6: "",
      selectElement1: true,
      selectElement2: false,
      selectElement3: false,
      selectElement4: false,
      selectElement5: false,
      selectElement6: false,
      isElement1: false,
      isElement2: false,
      isElement3: false,
      isElement4: false,
      isElement5: false,
      isElement6: false,
      element1: "",
      element2: "",
      element3: "",
      element4: "",
      element5: "",
      element6: "",
      positionUnitElement1: "0",
      positionUnitElement2: "0",
      positionUnitElement3: "0",
      positionUnitElement4: "0",
      positionUnitElement5: "0",
      positionUnitElement6: "0",
      calculatorType: "",
      selectedElement: 1,
      oldPath: "",
      loading: false,
      result: "",
      solverCategory: "",
    };
  }

  onFocusInput(event) {
    event.target.select();
  }

  componentDidMount() {
    this.getData();
    reportVisitService.report(window.location.pathname, document.title);
  }

  solveFormula() {
    this.setState({
      loading: true,
    });

    const data = {
      calculatorType: this.state.calculatorType,
      element: this.state.selectedElement,
      position1: this.state.positionUnitElement1,
      position2: this.state.positionUnitElement2,
      position3: this.state.positionUnitElement3,
      position4: this.state.positionUnitElement4,
      position5: this.state.positionUnitElement5,
      position6: this.state.positionUnitElement6,
      q1: this.state.inputValueElement1,
      q2: this.state.inputValueElement2,
      q3: this.state.inputValueElement3,
      q4: this.state.inputValueElement4,
      q5: this.state.inputValueElement5,
      q6: this.state.inputValueElement6,
      baseUnit1: this.state.unitTypeElement1,
      baseUnit2: this.state.unitTypeElement2,
      baseUnit3: this.state.unitTypeElement3,
      baseUnit4: this.state.unitTypeElement4,
      baseUnit5: this.state.unitTypeElement5,
      baseUnit6: this.state.unitTypeElement6,
    };

    switch (this.state.solverCategory) {
      case "electricity-and-magnetismus":
        SolverService.solveElectricityAndMagnetism(data)
          .then((response) => {
            this.parseResponse(response);
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
          });
        break;
      case "hydromechanics":
        SolverService.solveHydromechanics(data)
          .then((response) => {
            this.parseResponse(response);
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
          });
        break;
      case "mechanical-vibration-waves-acoustic":
        SolverService.solveMechanicalVibrationWavesAndAcoustic(data)
          .then((response) => {
            this.parseResponse(response);
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
          });
        break;
      case "mechanics":
        SolverService.solveMechanics(data)
          .then((response) => {
            this.parseResponse(response);
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
          });
        break;
      case "molecular-physics-thermodynamic":
        SolverService.solveMolecularPhysicsAndThermodynamic(data)
          .then((response) => {
            this.parseResponse(response);
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
          });
        break;
      case "optics":
        SolverService.solveOptics(data)
          .then((response) => {
            this.parseResponse(response);
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
          });
        break;
      case "quantum-physics":
        SolverService.solveQuantumPhysics(data)
          .then((response) => {
            this.parseResponse(response);
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
          });
        break;
      case "special-theory-of-relativity":
        SolverService.solveTheSpecialTheoryOfRelativity(data)
          .then((response) => {
            this.parseResponse(response);
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
          });
        break;
      case "chemistry":
        SolverService.solveChemistry(data)
          .then((response) => {
            this.parseResponse(response);
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
          });
        break;
    }
  }

  parseResponse(response) {
    if (response.data.status === "Success") {
      var element = "",
        unit = "";
      var selects = document.getElementsByClassName("select");

      if (this.state.selectElement1) {
        element = this.state.element1;
        unit = selects[0].options[selects[0].selectedIndex].text;
      } else if (this.state.selectElement2) {
        element = this.state.element2;
        unit = selects[1].options[selects[1].selectedIndex].text;
      } else if (this.state.selectElement3) {
        element = this.state.element3;
        unit = selects[2].options[selects[2].selectedIndex].text;
      } else if (this.state.selectElement4) {
        element = this.state.element4;
        unit = selects[3].options[selects[3].selectedIndex].text;
      } else if (this.state.selectElement5) {
        element = this.state.element5;
        unit = selects[4].options[selects[4].selectedIndex].text;
      } else if (this.state.selectElement6) {
        element = this.state.element6;
        unit = selects[5].options[selects[5].selectedIndex].text;
      }

      var sResult = element + " = " + response.data.result + " " + unit;
      this.setState({
        result: sResult,
        errorMessage: "",
        loading: false,
      });
    } else {
      this.setState({
        result: "",
        errorMessage: response.data.message,
        loading: false,
      });
    }
  }

  onInputValueElement1Change(event) {
    this.setState({
      inputValueElement1: event.target.valueAsNumber,
    });
  }

  onUnitPositionElement1Change(event) {
    this.setState({
      positionUnitElement1: event.target.value,
    });
  }

  onSelectElement1Change() {
    this.setState({
      selectElement1: true,
      selectElement2: false,
      selectElement3: false,
      selectElement4: false,
      selectElement5: false,
      selectElement6: false,
      selectedElement: 1,
    });
  }

  onInputValueElement2Change(event) {
    this.setState({
      inputValueElement2: event.target.valueAsNumber,
    });
  }

  onUnitPositionElement2Change(event) {
    this.setState({
      positionUnitElement2: event.target.value,
    });
  }

  onSelectElement2Change() {
    this.setState({
      selectElement1: false,
      selectElement2: true,
      selectElement3: false,
      selectElement4: false,
      selectElement5: false,
      selectElement6: false,
      selectedElement: 2,
    });
  }

  onInputValueElement3Change(event) {
    this.setState({
      inputValueElement3: event.target.valueAsNumber,
    });
  }

  onUnitPositionElement3Change(event) {
    this.setState({
      positionUnitElement3: event.target.value,
    });
  }

  onSelectElement3Change() {
    this.setState({
      selectElement1: false,
      selectElement2: false,
      selectElement3: true,
      selectElement4: false,
      selectElement5: false,
      selectElement6: false,
      selectedElement: 3,
    });
  }

  onInputValueElement4Change(event) {
    this.setState({
      inputValueElement4: event.target.valueAsNumber,
    });
  }

  onUnitPositionElement4Change(event) {
    this.setState({
      positionUnitElement4: event.target.value,
    });
  }

  onSelectElement4Change() {
    this.setState({
      selectElement1: false,
      selectElement2: false,
      selectElement3: false,
      selectElement4: true,
      selectElement5: false,
      selectElement6: false,
      selectedElement: 4,
    });
  }

  onInputValueElement5Change(event) {
    this.setState({
      inputValueElement5: event.target.valueAsNumber,
    });
  }

  onUnitPositionElement5Change(event) {
    this.setState({
      positionUnitElement5: event.target.value,
    });
  }

  onSelectElement5Change() {
    this.setState({
      selectElement1: false,
      selectElement2: false,
      selectElement3: false,
      selectElement4: false,
      selectElement5: true,
      selectElement6: false,
      selectedElement: 5,
    });
  }

  onInputValueElement6Change(event) {
    this.setState({
      inputValueElement6: event.target.valueAsNumber,
    });
  }

  onUnitPositionElement6Change(event) {
    this.setState({
      positionUnitElement6: event.target.value,
    });
  }

  onSelectElement6Change() {
    this.setState({
      selectElement1: false,
      selectElement2: false,
      selectElement3: false,
      selectElement4: false,
      selectElement5: false,
      selectElement6: true,
      selectedElement: 6,
    });
  }

  autoHeight() {
    const tx = document.getElementsByTagName("textarea");
    for (let i = 0; i < tx.length; i++) {
      tx[i].setAttribute("style", "height:1px;overflow-y:hidden;");
      tx[i].setAttribute(
        "style",
        "height:" + tx[i].scrollHeight + "px;overflow-y:hidden;"
      );
    }
  }

  getData() {
    var path = window.location.pathname;
    if (this.state.oldPath !== path) {
      if (path.endsWith("/")) path = path.substring(0, path.length - 1);

      var data;
      var categoryName = "";

      var pathArray = path.substring(1).split("/");
      if (pathArray[0] === "chemistry") {
        data = chemistryHelper.getFormulaParams(pathArray[1]);
        this.setState({
          solverCategory: pathArray[0],
        });
      } else {
        switch (pathArray[1]) {
          case "electricity-and-magnetismus":
            data = electricityAndMagnetismusPhysicsHelper.getFormulaParams(
              pathArray[2]
            );
            categoryName = "Electricity and magnetismus";
            break;
          case "hydromechanics":
            data = hydromechanicsPhysicsHelper.getFormulaParams(pathArray[2]);
            categoryName = "Hydromechanics";
            break;
          case "mechanical-vibration-waves-acoustic":
            data =
              mechanicalVibrationWavesAcousticPhysicsHelper.getFormulaParams(
                pathArray[2]
              );
            categoryName = "Mechanical vibration, waves, acoustic";
            break;
          case "mechanics":
            data = mechanicsPhysicsHelper.getFormulaParams(pathArray[2]);
            categoryName = "Mechanics";
            break;
          case "molecular-physics-thermodynamic":
            data = molecularPhysicsThermodynamicPhysicsHelper.getFomulaParams(
              pathArray[2]
            );
            categoryName = "Molecular physics and thermodynamic";
            break;
          case "optics":
            data = opticsPhysicsHelper.getFormulaParmas(pathArray[2]);
            categoryName = "Optics";
            break;
          case "quantum-physics":
            data = quantumPhysicsPhysicsHelper.getFormulaParams(pathArray[2]);
            categoryName = "Quantum physics";
            break;
          case "special-theory-of-relativity":
            data = specialTheoryOfRelativityPhysicsHelper.getFormulaParams(
              pathArray[2]
            );
            categoryName = "Special theory of relativity";
            break;
        }
        this.setState({
          solverCategory: pathArray[1],
        });
      }

      const tempIsElement1 = data.element1 !== null;
      const tempIsElement2 = data.element2 !== null;
      const tempIsElement3 = data.element3 !== null;
      const tempIsElement4 = data.element4 !== null;
      const tempIsElement5 = data.element5 !== null;
      const tempIsElement6 = data.element6 !== null;

      document.title = data.title + " - " + categoryName + " | Formula Solver";

      this.props.setPageTitle(categoryName);

      this.setState(
        {
          title: data.title,
          calculatorType: data.calculatorType,
          description: data.description,
          imagePath:
            process.env.PUBLIC_URL + "/images/" + data.imagePath + ".png",
          unitTypeElement1: data.baseUnit1,
          unitTypeElement2: data.baseUnit2,
          unitTypeElement3: data.baseUnit3,
          unitTypeElement4: data.baseUnit4,
          unitTypeElement5: data.baseUnit5,
          unitTypeElement6: data.baseUnit6,
          isElement1: tempIsElement1,
          isElement2: tempIsElement2,
          isElement3: tempIsElement3,
          isElement4: tempIsElement4,
          isElement5: tempIsElement5,
          isElement6: tempIsElement6,
          element1: data.element1,
          element2: data.element2,
          element3: data.element3,
          element4: data.element4,
          element5: data.element5,
          element6: data.element6,
          oldPath: path,
        },
        () => {
          this.autoHeight();
        }
      );
    }
  }

  renderUnits(param, position, fun) {
    switch (param) {
      default:
      case "m":
        return Units.getLength(position, fun);
      case "l":
        return Units.getVolume(position, fun);
      case "kg/m³":
        return Units.getDensity(position, fun);
      case "C":
        return Units.getCharge(position, fun);
      case "J/K":
        return Units.getThermalCapacity(position, fun);
      case "K":
        return Units.getHeat(position, fun);
      case "W/m²":
        return Units.getIntensityOfRadiation(position, fun);
      case "N":
        return Units.getForce(position, fun);
      case "Ω":
        return Units.getResistance(position, fun);
      case "H":
        return Units.getInductance(position, fun);
      case "F":
        return Units.getCapacity(position, fun);
      case "Hz":
        return Units.getFrequency(position, fun);
      case "m/s":
        return Units.getSpeed(position, fun);
      case "kg":
        return Units.getWeight(position, fun);
      case "J":
        return Units.getWork(position, fun);
      case "m/s²":
        return Units.getAcceleration(position, fun);
      case "rad/s":
        return Units.getAngularSpeed(position, fun);
      case "Ns":
        return Units.getImpulsOfForce(position, fun);
      case "Nm":
        return Units.getMoment(position, fun);
      case "V":
        return Units.getVoltage(position, fun);
      case "A":
        return Units.getCurrent(position, fun);
      case "T":
        return Units.getMagneticInduction(position, fun);
      case "m²":
        return Units.getContent(position, fun);
      case "s":
        return Units.getTime(position, fun);
      case "Wb":
        return Units.getMagneticFlux(position, fun);
      case "Pa":
        return Units.getPressure(position, fun);
      case "W":
        return Units.getPerformance(position, fun);
      case "mol":
        return Units.getSubstanceAmount(position, fun);
      case "g/mol":
        return Units.getMolarWeight(position, fun);
    }
  }

  render() {
    return (
      <div className="main">
        {this.state.loading ? <Loader /> : null}

        <h1 className="title_list">{this.state.title}</h1>

        <div className="div_formula_description">
          <img
            src={this.state.imagePath}
            alt={this.state.title}
            title={this.state.title}
            className="image_formula"
          />

          <br />

          <textarea
            type="text"
            className="description_formula"
            readOnly
            value={this.state.description}
          ></textarea>
        </div>

        <div className="div_elements">
          {this.state.isElement1 ? (
            <table className="table">
              <th className="th_radio">
                <button
                  className={
                    this.state.selectElement1
                      ? "btn_radio btn_checked"
                      : "btn_radio btn_unchecked"
                  }
                  onClick={this.onSelectElement1Change}
                />{" "}
                <label className="label_radio">{this.state.element1}</label>
              </th>
              <th className="th_input">
                <input
                  type="number"
                  className="element_value"
                  onFocus={this.onFocusInput}
                  value={this.state.inputValueElement1}
                  onChange={this.onInputValueElement1Change}
                />
              </th>
              <th className="th_spinner">
                {this.renderUnits(
                  this.state.unitTypeElement1,
                  this.state.positionUnitElement1,
                  this.onUnitPositionElement1Change
                )}
              </th>
            </table>
          ) : (
            <div />
          )}

          {this.state.isElement2 ? (
            <table className="table">
              <th className="th_radio">
                <button
                  className={
                    this.state.selectElement2
                      ? "btn_radio btn_checked"
                      : "btn_radio btn_unchecked"
                  }
                  onClick={this.onSelectElement2Change}
                />{" "}
                <label className="label_radio">{this.state.element2}</label>
              </th>
              <th className="th_input">
                <input
                  type="number"
                  className="element_value"
                  onFocus={this.onFocusInput}
                  value={this.state.inputValueElement2}
                  onChange={this.onInputValueElement2Change}
                />
              </th>
              <th className="th_spinner">
                {this.renderUnits(
                  this.state.unitTypeElement2,
                  this.state.positionUnitElement2,
                  this.onUnitPositionElement2Change
                )}
              </th>
            </table>
          ) : (
            <div />
          )}

          {this.state.isElement3 ? (
            <table className="table">
              <th className="th_radio">
                <button
                  className={
                    this.state.selectElement3
                      ? "btn_radio btn_checked"
                      : "btn_radio btn_unchecked"
                  }
                  onClick={this.onSelectElement3Change}
                />{" "}
                <label className="label_radio">{this.state.element3}</label>
              </th>
              <th className="th_input">
                <input
                  type="number"
                  className="element_value"
                  onFocus={this.onFocusInput}
                  value={this.state.inputValueElement3}
                  onChange={this.onInputValueElement3Change}
                />
              </th>
              <th className="th_spinner">
                {this.renderUnits(
                  this.state.unitTypeElement3,
                  this.state.positionUnitElement3,
                  this.onUnitPositionElement3Change
                )}
              </th>
            </table>
          ) : (
            <div />
          )}

          {this.state.isElement4 ? (
            <table className="table">
              <th className="th_radio">
                <button
                  className={
                    this.state.selectElement4
                      ? "btn_radio btn_checked"
                      : "btn_radio btn_unchecked"
                  }
                  onClick={this.onSelectElement4Change}
                />{" "}
                <label className="label_radio">{this.state.element4}</label>
              </th>
              <th className="th_input">
                <input
                  type="number"
                  className="element_value"
                  onFocus={this.onFocusInput}
                  value={this.state.inputValueElement4}
                  onChange={this.onInputValueElement4Change}
                />
              </th>
              <th className="th_spinner">
                {this.renderUnits(
                  this.state.unitTypeElement4,
                  this.state.positionUnitElement4,
                  this.onUnitPositionElement4Change
                )}
              </th>
            </table>
          ) : (
            <div />
          )}

          {this.state.isElement5 ? (
            <table className="table">
              <th className="th_radio">
                <button
                  className={
                    this.state.selectElement5
                      ? "btn_radio btn_checked"
                      : "btn_radio btn_unchecked"
                  }
                  onClick={this.onSelectElement5Change}
                />{" "}
                <label className="label_radio">{this.state.element5}</label>
              </th>
              <th className="th_input">
                <input
                  type="number"
                  className="element_value"
                  onFocus={this.onFocusInput}
                  value={this.state.inputValueElement5}
                  onChange={this.onInputValueElement5Change}
                />
              </th>
              <th className="th_spinner">
                {this.renderUnits(
                  this.state.unitTypeElement5,
                  this.state.positionUnitElement5,
                  this.onUnitPositionElement5Change
                )}
              </th>
            </table>
          ) : (
            <div />
          )}

          {this.state.isElement6 ? (
            <table className="table">
              <th className="th_radio">
                <button
                  className={
                    this.state.selectElement6
                      ? "btn_radio btn_checked"
                      : "btn_radio btn_unchecked"
                  }
                  onClick={this.onSelectElement6Change}
                />{" "}
                <label className="label_radio">{this.state.element6}</label>
              </th>
              <th className="th_input">
                <input
                  type="number"
                  className="element_value"
                  onFocus={this.onFocusInput}
                  value={this.state.inputValueElement6}
                  onChange={this.onInputValueElement6Change}
                />
              </th>
              <th className="th_spinner">
                {this.renderUnits(
                  this.state.unitTypeElement6,
                  this.state.positionUnitElement6,
                  this.onUnitPositionElement6Change
                )}
              </th>
            </table>
          ) : (
            <div />
          )}
        </div>

        <label className="result">{this.state.result}</label>

        <br />

        <div className="div_button">
          <button className="btn_solve" onClick={this.solveFormula}>
            Solve
          </button>
        </div>
      </div>
    );
  }
}
