class Units {
  getLength(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">m</option>
        <option value="2">mm</option>
        <option value="3">cm</option>
        <option value="4">dm</option>
        <option value="5">km</option>
        <option value="6">in</option>
        <option value="7">ft</option>
        <option value="8">mi</option>
        <option value="9">yd</option>
      </select>
    );
  }

  getVolume(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">l</option>
        <option value="2">ml</option>
        <option value="3">cl</option>
        <option value="4">dl</option>
        <option value="5">hl</option>
        <option value="6">dm³</option>
        <option value="7">m³</option>
      </select>
    );
  }

  getDensity(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">kg/m³</option>
        <option value="2">g/m³</option>
      </select>
    );
  }

  getCharge(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">C</option>
        <option value="2">mC</option>
        <option value="3">µC</option>
        <option value="4">nC</option>
        <option value="5">pC</option>
        <option value="6">kC</option>
        <option value="7">MC</option>
      </select>
    );
  }

  getThermalCapacity(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">J/K</option>
        <option value="2">kJ/K</option>
      </select>
    );
  }

  getHeat(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">K</option>
        <option value="2">°C</option>
      </select>
    );
  }

  getIntensityOfRadiation(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">W/m²</option>
        <option value="2">kW/m²</option>
        <option value="3">MW/m²</option>
        <option value="4">GW/m²</option>
      </select>
    );
  }

  getForce(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">N</option>
        <option value="2">kN</option>
        <option value="3">MN</option>
        <option value="4">GN</option>
      </select>
    );
  }

  getResistance(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">Ω</option>
        <option value="2">mΩ</option>
        <option value="3">μΩ</option>
        <option value="4">nΩ</option>
        <option value="5">pΩ</option>
        <option value="6">kΩ</option>
        <option value="7">MΩ</option>
      </select>
    );
  }

  getInductance(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">H</option>
        <option value="2">mH</option>
        <option value="3">μH</option>
        <option value="4">nH</option>
        <option value="5">pH</option>
        <option value="6">kH</option>
        <option value="7">MH</option>
      </select>
    );
  }

  getCapacity(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">F</option>
        <option value="2">mF</option>
        <option value="3">μF</option>
        <option value="4">nF</option>
        <option value="5">pF</option>
        <option value="6">kF</option>
        <option value="7">MF</option>
      </select>
    );
  }

  getFrequency(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">Hz</option>
        <option value="2">kHz</option>
        <option value="3">MHz</option>
        <option value="4">GHz</option>
      </select>
    );
  }

  getSpeed(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">m/s</option>
        <option value="2">km/h</option>
        <option value="3">m/min</option>
        <option value="4">MPH</option>
        <option value="5">ft/hour</option>
        <option value="6">ft/s</option>
        <option value="7">kn</option>
      </select>
    );
  }

  getWeight(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">kg</option>
        <option value="2">mg</option>
        <option value="3">g</option>
        <option value="4">dkg</option>
        <option value="5">t</option>
      </select>
    );
  }

  getWork(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">J</option>
        <option value="2">kJ</option>
        <option value="3">MJ</option>
        <option value="4">GJ</option>
      </select>
    );
  }

  getAcceleration(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">m/s²</option>
        <option value="2">km/h²</option>
      </select>
    );
  }

  getAngularSpeed(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">rad/s</option>
        <option value="2">°/s</option>
      </select>
    );
  }

  getImpulsOfForce(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">Ns</option>
        <option value="2">kNs</option>
        <option value="3">MNs</option>
        <option value="4">Nh</option>
        <option value="5">kNh</option>
        <option value="6">MNh</option>
      </select>
    );
  }

  getMoment(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">Nm</option>
        <option value="2">Nkm</option>
        <option value="3">kNm</option>
        <option value="4">kNkm</option>
        <option value="5">MNm</option>
        <option value="6">MNkm</option>
        <option value="7">GNm</option>
        <option value="8">GNkm</option>
      </select>
    );
  }

  getVoltage(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">V</option>
        <option value="2">mV</option>
        <option value="3">μV</option>
        <option value="4">nV</option>
        <option value="5">pV</option>
        <option value="6">kV</option>
        <option value="7">MV</option>
      </select>
    );
  }

  getCurrent(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">A</option>
        <option value="2">mA</option>
        <option value="3">μA</option>
        <option value="4">nA</option>
        <option value="5">pA</option>
        <option value="6">kA</option>
        <option value="7">MA</option>
      </select>
    );
  }

  getMagneticInduction(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">T</option>
        <option value="2">mT</option>
        <option value="3">μT</option>
        <option value="4">nT</option>
        <option value="5">pT</option>
        <option value="6">kT</option>
        <option value="7">MT</option>
      </select>
    );
  }

  getContent(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">m²</option>
        <option value="2">mm²</option>
        <option value="3">cm²</option>
        <option value="4">dm²</option>
        <option value="5">km²</option>
        <option value="6">ha</option>
      </select>
    );
  }

  getTime(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">s</option>
        <option value="2">min</option>
        <option value="3">h</option>
      </select>
    );
  }

  getMagneticFlux(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">Wb</option>
        <option value="2">mWb</option>
        <option value="3">μWb</option>
        <option value="4">nWb</option>
        <option value="5">pWb</option>
        <option value="6">kWb</option>
        <option value="7">MWb</option>
      </select>
    );
  }

  getPressure(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">Pa</option>
        <option value="2">hPa</option>
        <option value="3">kPa</option>
        <option value="4">MPa</option>
        <option value="5">bar</option>
        <option value="6">atm</option>
        <option value="7">mbar</option>
        <option value="8">nbar</option>
      </select>
    );
  }

  getPerformance(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">W</option>
        <option value="2">mW</option>
        <option value="3">kW</option>
        <option value="4">MW</option>
        <option value="5">GW</option>
        <option value="6">TW</option>
      </select>
    );
  }

  getSubstanceAmount(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">mol</option>
        <option value="2">kmol</option>
      </select>
    );
  }

  getMolarWeight(position, fun) {
    return (
      <select value={position} className="select" onChange={fun}>
        <option value="1">g/mol</option>
        <option value="2">kg/mol</option>
      </select>
    );
  }
}

export default new Units();
