import Strings from "./strings.helper";

class Mechanics {
    getFormulas() {
        const titleString = "Mechanics";
        var namesList = [];
        var linksList = [];
        var itemsList = [];

        linksList.push("/physics/mechanics/density");
        namesList.push("Density");

        linksList.push("/physics/mechanics/momentum");
        namesList.push("Momentum");

        linksList.push("/physics/mechanics/impulse-of-force");
        namesList.push("Impulse of force");

        linksList.push("/physics/mechanics/second-law-of-motion1");
        namesList.push("Second law of motion");

        linksList.push("/physics/mechanics/second-law-of-motion2");
        namesList.push("Second law of motion if m is constant");

        linksList.push("/physics/mechanics/gravitational-force");
        namesList.push("Gravitational force");

        linksList.push("/physics/mechanics/centripetal-force1");
        namesList.push("Centripetal force 1");

        linksList.push("/physics/mechanics/centripetal-force2");
        namesList.push("Centripetal force 2");

        linksList.push("/physics/mechanics/the-inclination-of-the-road-in-a-curve");
        namesList.push("The inclination of the road in a curve");

        linksList.push("/physics/mechanics/moment-of-force");
        namesList.push("Moment of force");

        linksList.push("/physics/mechanics/balance-the-two-forces");
        namesList.push("Balance the two forces");

        linksList.push("/physics/mechanics/simple-couple");
        namesList.push("Simple couple");

        linksList.push("/physics/mechanics/mechanical-work1");
        namesList.push("Mechanical work 1");

        linksList.push("/physics/mechanics/mechanical-work2");
        namesList.push("Mechanical work 2");

        linksList.push("/physics/mechanics/mechanical-work3");
        namesList.push("Mechanical work if cos α : 1");

        linksList.push("/physics/mechanics/kinetic-energy");
        namesList.push("Kinetic energy");

        linksList.push("/physics/mechanics/potential-energy");
        namesList.push("Potential energy");

        linksList.push("/physics/mechanics/energy-conservation");
        namesList.push("Energy conservation 1");

        linksList.push("/physics/mechanics/energy-conservation2");
        namesList.push("Energy conservation 2");

        linksList.push("/physics/mechanics/power");
        namesList.push("Power");

        linksList.push("/physics/mechanics/power-with-force");
        namesList.push("Power with force");

        linksList.push("/physics/mechanics/efficiency-of-work");
        namesList.push("Efficiency of work");

        linksList.push("/physics/mechanics/efficiency-of-power");
        namesList.push("Efficiency of power");

        linksList.push("/physics/mechanics/angular-kinetic-energy");
        namesList.push("Angular kinetic energy");

        linksList.push("/physics/mechanics/friction");
        namesList.push("Friction");

        linksList.push("/physics/mechanics/rolling-resistance");
        namesList.push("Rolling resistance");

        linksList.push("/physics/mechanics/weighing");
        namesList.push("Weighing");

        linksList.push("/physics/mechanics/newtons-law-of-universal-gravitation");
        namesList.push("Newton's law of universal gravitation");

        linksList.push("/physics/mechanics/work-done-by-gravitational-field");
        namesList.push("Work done by gravitational field");

        linksList.push("/physics/mechanics/gravitational-potential-energy");
        namesList.push("Gravitational potential energy");

        linksList.push("/physics/mechanics/uniform-motion-velocity");
        namesList.push("Velocity of uniform motion");

        linksList.push("/physics/mechanics/uniform-motion-path");
        namesList.push("Path of uniform motion");

        linksList.push("/physics/mechanics/linear-motion-acceleration1");
        namesList.push("Acceleration linear motion");

        linksList.push("/physics/mechanics/linear-motion-acceleration2");
        namesList.push("Linear motion - acceleration when starting from rest");

        linksList.push("/physics/mechanics/linear-motion-velocity1");
        namesList.push("Velocity of linear motion");

        linksList.push("/physics/mechanics/linear-motion-velocity2");
        namesList.push("Linear motion - velocity when starting from rest 1");

        linksList.push("/physics/mechanics/linear-motion-velocity3");
        namesList.push("Linear motion - velocity when starting from rest 2");

        linksList.push("/physics/mechanics/linear-motion-path1");
        namesList.push("Linear motion - path when starting from rest 1");

        linksList.push("/physics/mechanics/linear-motion-path2");
        namesList.push("Linear motion - path when starting from rest 2");

        linksList.push("/physics/mechanics/free-fall-impact-velocity-from-given-height");
        namesList.push("Impact velocity from given height h of free fall");

        linksList.push("/physics/mechanics/free-fall-velocity-ff");
        namesList.push("Velocity of free fall");

        linksList.push("/physics/mechanics/free-fall-path-ff");
        namesList.push("Path of free fall");

        linksList.push("/physics/mechanics/projectile-motion-up-velocity-pmu");
        namesList.push("Projectile motion up - velocity");

        linksList.push("/physics/mechanics/projectile-motion-up-time-of-rise");
        namesList.push("Projectile motion up - time of rise");

        linksList.push("/physics/mechanics/projectile-motion-up-height-of-projectile");
        namesList.push("Projectile motion up - height of projectile");

        linksList.push("/physics/mechanics/uniformly-circular-motion-circular-path");
        namesList.push("Circular path of uniform circular motion");

        linksList.push("/physics/mechanics/uniformly-circular-motion-angular-velocity1");
        namesList.push("Angular velocity of uniform circular motion");

        linksList.push("/physics/mechanics/uniformly-circular-motion-angular-velocity2");
        namesList.push("Angular velocity of uniform circular motion");

        linksList.push("/physics/mechanics/uniformly-circular-motion-velocity1-ucm");
        namesList.push("Velocity of uniform circular motion");

        linksList.push("/physics/mechanics/uniformly-circular-motion-velocity2-ucm");
        namesList.push("Velocity of uniform circular motion 2");

        linksList.push("/physics/mechanics/uniformly-circular-motion-velocity3-ucm");
        namesList.push("Velocity of uniform circular motion 3");

        linksList.push("/physics/mechanics/uniformly-circular-motion-period-of-motion");
        namesList.push("Period of motion of uniform circular motion");

        linksList.push("/physics/mechanics/uniformly-circular-motion-frequency-of-motion");
        namesList.push("Frequency of motion of uniform circular motion");

        linksList.push("/physics/mechanics/uniformly-circular-motion-acceleration1-ucm");
        namesList.push("Acceleration of uniform circular motion");

        linksList.push("/physics/mechanics/moment-of-inertia-fixed-point");
        namesList.push("Moment of inertia of fixed point");

        linksList.push("/physics/mechanics/moment-of-inertia-solid-cylinder");
        namesList.push("Moment of inertia of solid cylinder");

        linksList.push("/physics/mechanics/moment-of-inertia-solid-sphere");
        namesList.push("Moment of inertia of solid sphere");

        linksList.push("/physics/mechanics/simple-machine-lever");
        namesList.push("Simple machine - lever");

        linksList.push("/physics/mechanics/simple-machine-pulley");
        namesList.push("Simple machine - pulley");

        linksList.push("/physics/mechanics/simple-machine-wheel-and-axle");
        namesList.push("Simple machine - wheel and axle");

        linksList.push("/physics/mechanics/simple-machine-inclined-plane1");
        namesList.push("Simple machine - inclined plane 1");

        linksList.push("/physics/mechanics/simple-machine-inclined-plane2");
        namesList.push("Simple machine - inclined plane 2");

        linksList.push("/physics/mechanics/gravitational-field-intensity-above-planets-surface");
        namesList.push("Gravitational field intensity above planet's surface");

        linksList.push("/physics/mechanics/gravitational-field-intensity-on-planets-surface");
        namesList.push("Gravitational field intensity on planet's surface");

        linksList.map((linkT, index) => {
            var item = {
                link: linkT,
                name: namesList[index]
            }

            itemsList.push(item);
        });

        return {
            title: titleString,
            items: itemsList
        }
    }

    getFormulaParams(formulaName) {
        switch (formulaName) {
            case "density":
               return this.density();
            case "momentum":
               return this.momentum();
            case "impulse-of-force":
               return this.impulse_of_force();
            case "second-law-of-motion1":
               return this.second_law_of_motion1();
            case "second-law-of-motion2":
               return this.second_law_of_motion2();
            case "gravitational-force":
               return this.gravitational_force();
            case "centripetal-force1":
               return this.centripetal_force1();
            case "centripetal-force2":
               return this.centripetal_force2();
            case "the-inclination-of-the-road-in-a-curve":
               return this.the_inclination_of_the_road_in_a_curve();
            case "moment-of-force":
               return this.moment_of_force();
            case "balance-the-two-forces":
               return this.balance_the_two_forces();
            case "simple-couple":
               return this.simple_couple();
            case "mechanical-work1":
               return this.mechanical_work1();
            case "mechanical-work2":
               return this.mechanical_work2();
            case "mechanical-work3":
               return this.mechanical_work3();
            case "kinetic-energy":
               return this.kinetic_energy();
            case "potential-energy":
               return this.potential_energy();
            case "energy-conservation":
               return this.energy_conservation();
            case "energy-conservation2":
               return this.energy_conservation2();
            case "power":
               return this.power();
            case "power-with-force":
               return this.power_with_force();
            case "efficiency-of-work":
               return this.efficiency_of_work();
            case "efficiency-of-power":
               return this.efficiency_of_power();
            case "angular-kinetic-energy":
               return this.angular_kinetic_energy();
            case "friction":
               return this.friction();
            case "rolling-resistance":
               return this.rolling_resistance();
            case "weighing":
               return this.weighing();
            case "newtons-law-of-universal-gravitation":
               return this.newtons_law_of_universal_gravitation();
            case "work-done-by-gravitational-field":
               return this.work_done_by_gravitational_field();
            case "gravitational-potential-energy":
               return this.gravitational_potential_energy();
            case "uniform-motion-velocity":
               return this.uniform_motion_velocity();
            case "uniform-motion-path":
               return this.uniform_motion_path();
            case "linear-motion-acceleration1":
               return this.linear_motion_acceleration1();
            case "linear-motion-acceleration2":
               return this.linear_motion_acceleration2();
            case "linear-motion-velocity1":
               return this.linear_motion_velocity1();
            case "linear-motion-velocity2":
               return this.linear_motion_velocity2();
            case "linear-motion-velocity3":
               return this.linear_motion_velocity3();
            case "linear-motion-path1":
               return this.linear_motion_path1();
            case "linear-motion-path2":
               return this.linear_motion_path2();
            case "free-fall-impact-velocity-from-given-height":
               return this.free_fall_impact_velocity_from_given_height();
            case "free-fall-velocity-ff":
               return this.free_fall_velocity_ff();
            case "free-fall-path-ff":
               return this.free_fall_path_ff();
            case "projectile-motion-up-velocity-pmu":
               return this.projectile_motion_up_velocity_pmu();
            case "projectile-motion-up-time-of-rise":
               return this.projectile_motion_up_time_of_rise();
            case "projectile-motion-up-height-of-projectile":
               return this.projectile_motion_up_height_of_projectile();
            case "uniformly-circular-motion-circular-path":
               return this.uniformly_circular_motion_circular_path();
            case "uniformly-circular-motion-angular-velocity1":
               return this.uniformly_circular_motion_angular_velocity1();
            case "uniformly-circular-motion-angular-velocity2":
               return this.uniformly_circular_motion_angular_velocity2();
            case "uniformly-circular-motion-velocity1-ucm":
               return this.uniformly_circular_motion_velocity1_ucm();
            case "uniformly-circular-motion-velocity2-ucm":
               return this.uniformly_circular_motion_velocity2_ucm();
            case "uniformly-circular-motion-velocity3-ucm":
               return this.uniformly_circular_motion_velocity3_ucm();
            case "uniformly-circular-motion-period-of-motion":
               return this.uniformly_circular_motion_period_of_motion();
            case "uniformly-circular-motion-frequency-of-motion":
               return this.uniformly_circular_motion_frequency_of_motion();
            case "uniformly-circular-motion-acceleration1-ucm":
               return this.uniformly_circular_motion_acceleration1_ucm();
            case "moment-of-inertia-fixed-point":
               return this.moment_of_inertia_fixed_point();
            case "moment-of-inertia-solid-cylinder":
               return this.moment_of_inertia_solid_cylinder();
            case "moment-of-inertia-solid-sphere":
               return this.moment_of_inertia_solid_sphere();
            case "simple-machine-lever":
               return this.simple_machine_lever();
            case "simple-machine-pulley":
               return this.simple_machine_pulley();
            case "simple-machine-wheel-and-axle":
               return this.simple_machine_wheel_and_axle();
            case "simple-machine-inclined-plane1":
               return this.simple_machine_inclined_plane1();
            case "simple-machine-inclined-plane2":
               return this.simple_machine_inclined_plane2();
            case "gravitational-field-intensity-above-planets-surface":
               return this.gravitational_field_intensity_above_planets_surface();
            case "gravitational-field-intensity-on-planets-surface":
               return this.gravitational_field_intensity_on_planets_surface();
        }
    }

    density() {
        return {
            calculatorType: "e1Ee2De3",
            imagePath: "density",
            title: Strings.density_(),
            description: Strings.density__description(),
            element1: "ρ",
            baseUnit1: "g/m^3",
            element2: "m",
            baseUnit2: "kg",
            element3: "V",
            baseUnit3: "l",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    momentum() {
        return {
            calculatorType: "e1Ee2e3",
            imagePath: "momentum",
            title: Strings.momentum(),
            description: Strings.momentum_description(),
            element1: "p",
            baseUnit1: "kg*m/s",
            element2: "m",
            baseUnit2: "kg",
            element3: "v",
            baseUnit3: "m/s",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    impulse_of_force() {
        return {
            calculatorType: "e1Ee2e3",
            imagePath: "impulse_of_force",
            title: Strings.impulse_of_force(),
            description: Strings.impulse_of_force_description(),
            element1: "I",
            baseUnit1: "Ns",
            element2: "F",
            baseUnit2: "N",
            element3: "Δt",
            baseUnit3: "s",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    second_law_of_motion1() {
        return {
            calculatorType: "e1Ee2De3",
            imagePath: "second_law_of_motion",
            title: Strings.second_law_of_motion1(),
            description: Strings.second_law_of_motion1_description(),
            element1: "F",
            baseUnit1: "N",
            element2: "Δp",
            baseUnit2: "kgm/s",
            element3: "Δt",
            baseUnit3: "s",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    second_law_of_motion2() {
        return {
            calculatorType: "e1Ee2e3",
            imagePath: "second_law_of_motion__",
            title: Strings.second_law_of_motion2(),
            description: Strings.second_law_of_motion2_description(),
            element1: "F",
            baseUnit1: "N",
            element2: "m",
            baseUnit2: "kg",
            element3: "a",
            baseUnit3: "m/s^2",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    gravitational_force() {
        return {
            calculatorType: "e1Ee2e3",
            imagePath: "gravitational_force",
            title: Strings.gravitational_force(),
            description: Strings.gravitational_force1_description(),
            element1: "Fg",
            baseUnit1: "N",
            element2: "m",
            baseUnit2: "kg",
            element3: "g",
            baseUnit3: "m/s^2",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    centripetal_force1() {
        return {
            calculatorType: "e1Ee2e3p2e4",
            imagePath: "centripetal_force1",
            title: Strings.centripetal_force1(),
            description: Strings.centripetal_force1_description(),
            element1: "F",
            baseUnit1: "N",
            element2: "m",
            baseUnit2: "kg",
            element3: "ω",
            baseUnit3: "s^-1",
            element4: "r",
            baseUnit4: "m",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    centripetal_force2() {
        return {
            calculatorType: "e1Ee2e3p2De4",
            imagePath: "centripetal_force2",
            title: Strings.centripetal_force2(),
            description: Strings.centripetal_force2_description(),
            element1: "F",
            baseUnit1: "N",
            element2: "m",
            baseUnit2: "kg",
            element3: "v",
            baseUnit3: "m/s",
            element4: "r",
            baseUnit4: "m",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    the_inclination_of_the_road_in_a_curve() {
        return {
            calculatorType: "tge1Ee2P2De3e4",
            imagePath: "the_inclination_of_road",
            title: Strings.the_inclination_of_the_road_in_a_curve(),
            description: Strings.the_inclination_of_the_road_in_a_curve_description(),
            element1: "α",
            baseUnit1: "°",
            element2: "v",
            baseUnit2: "m/s",
            element3: "g",
            baseUnit3: "m/s^2",
            element4: "r",
            baseUnit4: "m",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    moment_of_force() {
        return {
            calculatorType: "e1Ee2e3",
            imagePath: "moment_of_force",
            title: Strings.moment_of_force(),
            description: Strings.moment_of_force_description(),
            element1: "M",
            baseUnit1: "Nm",
            element2: "r",
            baseUnit2: "m",
            element3: "F",
            baseUnit3: "N",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    balance_the_two_forces() {
        return {
            calculatorType: "e1e2Ee3e4",
            imagePath: "balance_the_two_forces",
            title: Strings.balance_the_two_forces(),
            description: Strings.balance_the_two_forces_description(),
            element1: "r1",
            baseUnit1: "m",
            element2: "F1",
            baseUnit2: "N",
            element3: "r2",
            baseUnit3: "m",
            element4: "F2",
            baseUnit4: "N",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    simple_couple() {
        return {
            calculatorType: "e1Ee2e3",
            imagePath: "simple_couple",
            title: Strings.simple_couple(),
            description: Strings.simple_couple_description(),
            element1: "D",
            baseUnit1: "Nm",
            element2: "F",
            baseUnit2: "N",
            element3: "d",
            baseUnit3: "m",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    mechanical_work1() {
        return {
            calculatorType: "e1Ee2e3cose4",
            imagePath: "mechanical_work1",
            title: Strings.mechanical_work1(),
            description: Strings.mechanical_work1_description(),
            element1: "W",
            baseUnit1: "J",
            element2: "F",
            baseUnit2: "N",
            element3: "s",
            baseUnit3: "m",
            element4: "α",
            baseUnit4: "°",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    mechanical_work2() {
        return {
            calculatorType: "e1Ee2e3",
            imagePath: "mechanical_work2",
            title: Strings.mechanical_work2(),
            description: Strings.mechanical_work2_description(),
            element1: "W",
            baseUnit1: "J",
            element2: "P",
            baseUnit2: "W",
            element3: "t",
            baseUnit3: "s",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    mechanical_work3() {
        return {
            calculatorType: "e1Ee2e3",
            imagePath: "mechanical_work3",
            title: Strings.mechanical_work3(),
            description: Strings.mechanical_work3_description(),
            element1: "W",
            baseUnit1: "J",
            element2: "F",
            baseUnit2: "N",
            element3: "s",
            baseUnit3: "m",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    kinetic_energy() {
        return {
            calculatorType: "e1E05e2e3P2",
            imagePath: "kinetic_energy",
            title: Strings.kinetic_energy(),
            description: Strings.kinetic_energy_description(),
            element1: "E",
            baseUnit1: "J",
            element2: "m",
            baseUnit2: "kg",
            element3: "v",
            baseUnit3: "m/s",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    potential_energy() {
        return {
            calculatorType: "e1Ee2e3e4",
            imagePath: "potential_energy",
            title: Strings.potential_energy(),
            description: Strings.potential_energy_description(),
            element1: "E",
            baseUnit1: "J",
            element2: "m",
            baseUnit2: "kg",
            element3: "g",
            baseUnit3: "m/s^2",
            element4: "h",
            baseUnit4: "m",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    energy_conservation() {
        return {
            calculatorType: "e1Ae2Ee3",
            imagePath: "energy_conservation",
            title: Strings.energy_conservation1(),
            description: Strings.energy_conservation1_description(),
            element1: "Ep",
            baseUnit1: "J",
            element2: "Ek",
            baseUnit2: "J",
            element3: "E",
            baseUnit3: "J",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    energy_conservation2() {
        return {
            calculatorType: "e1e2e3A05e1e4p2Ee5",
            imagePath: "energy_conservation2",
            title: Strings.energy_conservation2(),
            description: Strings.energy_conservation2_description(),
            element1: "m",
            baseUnit1: "kg",
            element2: "g",
            baseUnit2: "m/s^2",
            element3: "h",
            baseUnit3: "m",
            element4: "v",
            baseUnit4: "m/s",
            element5: "E",
            baseUnit5: "J",
            baseUnit6: null,
            element6: null
        }
    }

    power() {
        return {
            calculatorType: "e1Ee2De3",
            imagePath: "power",
            title: Strings.power(),
            description: Strings.power_description(),
            element1: "P",
            baseUnit1: "W",
            element2: "W",
            baseUnit2: "J",
            element3: "t",
            baseUnit3: "s",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    power_with_force() {
        return {
            calculatorType: "e1Ee2e3",
            imagePath: "power_with_force",
            title: Strings.power_with_f(),
            description: Strings.power_with_f_description(),
            element1: "P",
            baseUnit1: "W",
            element2: "F",
            baseUnit2: "N",
            element3: "v",
            baseUnit3: "m/s",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    efficiency_of_work() {
        return {
            calculatorType: "e1Ee2De3",
            imagePath: "efficiency_of_work",
            title: Strings.efficiency_of_work(),
            description: Strings.efficiency_of_work_description(),
            element1: "η",
            baseUnit1: " ",
            element2: "W'",
            baseUnit2: "J",
            element3: "W",
            baseUnit3: "J",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    efficiency_of_power() {
        return {
            calculatorType: "e1Ee2De3",
            imagePath: "efficiency_of_power",
            title: Strings.efficiency_of_power(),
            description: Strings.efficiency_of_power_description(),
            element1: "η",
            baseUnit1: " ",
            element2: "P'",
            baseUnit2: "W",
            element3: "P",
            baseUnit3: "W",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    angular_kinetic_energy() {
        return {
            calculatorType: "e1Ee2e3P2",
            imagePath: "angular_kinetic_energy",
            title: Strings.angular_kinetic_energy(),
            description: Strings.angular_kinetic_energy_description(),
            element1: "E",
            baseUnit1: "J",
            element2: "I",
            baseUnit2: "kgm^2",
            element3: "ω",
            baseUnit3: "rad/s",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }
    friction() {
        return {
            calculatorType: "e1Ee2e3",
            imagePath: "friction",
            title: Strings.friction(),
            description: Strings.friction_description(),
            element1: "F",
            baseUnit1: "N",
            element2: "f",
            baseUnit2: " ",
            element3: "Fn",
            baseUnit3: "N",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    rolling_resistance() {
        return {
            calculatorType: "e1Ee2de3Me4",
            imagePath: "rolling_resistance",
            title: Strings.rolling_resistance(),
            description: Strings.rolling_resistance_description(),
            element1: "F",
            baseUnit1: "N",
            element2: "ξ",
            baseUnit2: "m",
            element3: "r",
            baseUnit3: "m",
            element4: "Fn",
            baseUnit4: "N",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }
    weighing() {
        return {
            calculatorType: "e1ESQRTe2e3",
            imagePath: "weighing",
            title: Strings.weighing(),
            description: Strings.weighing_description(),
            element1: "M",
            baseUnit1: "kg",
            element2: "m1",
            baseUnit2: "kg",
            element3: "m2",
            baseUnit3: "kg",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    newtons_law_of_universal_gravitation() {
        return {
            calculatorType: "e1Ee2e3e4De5p2",
            imagePath: "newton_law_of",
            title: Strings.newtons_law_of_universal_gravitation(),
            description: Strings.newtons_law_of_universal_gravitation_description(),
            element1: "Fg",
            baseUnit1: "N",
            element2: "ϰ",
            baseUnit2: "m^3kg^-1s^-2",
            element3: "m1",
            baseUnit3: "kg",
            element4: "m2",
            baseUnit4: "kg",
            element5: "r",
            baseUnit5: "m",
            baseUnit6: null,
            element6: null
        }
    }

    work_done_by_gravitational_field() {
        return {
            calculatorType: "e1Ee2e3Me4se5",
            imagePath: "work_done_by",
            title: Strings.work_done_by_gravitational_field(),
            description: Strings.work_done_by_gravitational_field_description(),
            element1: "W",
            baseUnit1: "J",
            element2: "m",
            baseUnit2: "kg",
            element3: "K",
            baseUnit3: "N/kg",
            element4: "h1",
            baseUnit4: "m",
            element5: "h2",
            baseUnit5: "m",
            baseUnit6: null,
            element6: null

        }
    }

    gravitational_potential_energy() {
        return {
            calculatorType: "e1Ee2e3e4",
            imagePath: "gravitational_potential__",
            title: Strings.gravitational_potential_energy(),
            description: Strings.gravitational_potential_energy_description(),
            element1: "E",
            baseUnit1: "J",
            element2: "m",
            baseUnit2: "kg",
            element3: "K",
            baseUnit3: "N/kg",
            element4: "h",
            baseUnit4: "m",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    uniform_motion_velocity() {
        return {
            calculatorType: "e1Ee2De3",
            title: Strings.uniform_motion_velocity(),
            description: Strings._velocity_description(),
            element1: "v",
            baseUnit1: "m/s",
            element2: "s",
            baseUnit2: "m",
            element3: "t",
            baseUnit3: "s",
            imagePath: "uniform_motion_velocity",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    uniform_motion_path() {
        return {
            calculatorType: "e1Ee2e3",
            title: Strings.uniform_motion_path(),
            description: Strings._path_description(),
            element1: "s",
            baseUnit1: "m",
            element2: "v",
            baseUnit2: "m/s",
            element3: "t",
            baseUnit3: "s",
            imagePath: "uniform_motion_path",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    linear_motion_acceleration1() {
        return {
            calculatorType: "e1Ee2Se3De4",
            title: Strings.linear_motion_acceleration1(),
            description: Strings._acceleration1_description(),
            element1: "a",
            baseUnit1: "m/s^2",
            element2: "v",
            baseUnit2: "m/s",
            element3: "v0",
            baseUnit3: "m/s",
            element4: "t",
            baseUnit4: "s",
            imagePath: "linear_motin_acceleration",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    linear_motion_acceleration2() {
        return {
            calculatorType: "e1Ee2De3",
            title: Strings.linear_motion_acceleration2(),
            description: Strings._acceleration2_description(),
            element1: "a",
            baseUnit1: "m/s^2",
            element2: "v",
            baseUnit2: "m/s",
            element3: "t",
            baseUnit3: "s",
            imagePath: "linear_motin_acceleration_2",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    linear_motion_velocity1() {
        return {
            calculatorType: "e1Ee2Ce3Me4",
            title: Strings.linear_motion_velocity1(),
            description: Strings._velocity1_description(),
            element1: "v",
            baseUnit1: "m/s",
            element2: "v0",
            baseUnit2: "m/s",
            element3: "a",
            baseUnit3: "m/s^2",
            element4: "t",
            baseUnit4: "s",
            imagePath: "linear_motin_velocity",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    linear_motion_velocity2() {
        return {
            calculatorType: "e1Ee2e3",
            title: Strings.linear_motion_velocity2(),
            description: Strings._velocity2_description(),
            element1: "v",
            baseUnit1: "m/s",
            element2: "a",
            baseUnit2: "m/s^2",
            element3: "t",
            baseUnit3: "s",
            imagePath: "linear_motin_velocity_2",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    linear_motion_velocity3() {
        return {
            calculatorType: "e1ESQRT2e2e3",
            title: Strings.linear_motion_velocity3(),
            description: Strings._velocity3_description(),
            element1: "v",
            baseUnit1: "m/s",
            element2: "a",
            baseUnit2: "m/s^2",
            element3: "s",
            baseUnit3: "m",
            imagePath: "linear_motin_velocity_3",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    linear_motion_path1() {
        return {
            calculatorType: "e1E05e2e3P2",
            title: Strings.linear_motion_path1(),
            description: Strings._path1_description(),
            element1: "s",
            baseUnit1: "m",
            element2: "a",
            baseUnit2: "m/s^2",
            element3: "t",
            baseUnit3: "s",
            imagePath: "linear_motin_path",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    linear_motion_path2() {
        return {
            calculatorType: "e1E05e2Me3",
            title: Strings.linear_motion_path2(),
            description: Strings._path2_description(),
            element1: "s",
            baseUnit1: "m",
            element2: "v",
            baseUnit2: "m/s",
            element3: "t",
            baseUnit3: "s",
            imagePath: "linear_motin_path_2",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    free_fall_impact_velocity_from_given_height() {
        return {
            calculatorType: "e1ESQRT2e2e3",
            title: Strings.free_fall_impact_velocity_from_given_height(),
            description: Strings._impact_velocity_from_given_height_description(),
            element1: "v",
            baseUnit1: "m/s",
            element2: "g",
            baseUnit2: "m/s^2",
            element3: "h",
            baseUnit3: "m",
            imagePath: "free_fall_impact_velocity",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    free_fall_velocity_ff() {
        return {
            calculatorType: "e1Ee2e3",
            title: Strings.free_fall_velocity_ff(),
            description: Strings._velocity_ff_description(),
            element1: "v",
            baseUnit1: "m/s",
            element2: "g",
            baseUnit2: "m/s^2",
            element3: "t",
            baseUnit3: "s",
            imagePath: "free_fall_velocity",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    free_fall_path_ff() {
        return {
            calculatorType: "e1E05e2e3P2",
            title: Strings.free_fall_path_ff(),
            description: Strings._path_ff_description(),
            element1: "s",
            baseUnit1: "m",
            element2: "g",
            baseUnit2: "m/s^2",
            element3: "t",
            baseUnit3: "s",
            imagePath: "free_fall_path",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    projectile_motion_up_velocity_pmu() {
        return {
            calculatorType: "e1Ee2Se3Me4",
            title: Strings.projectile_motion_up_velocity_pmu(),
            description: Strings._velocity_pmu_description(),
            element1: "v",
            baseUnit1: "m/s",
            element2: "v0",
            baseUnit2: "m/s",
            element3: "g",
            baseUnit3: "m/s^2",
            element4: "t",
            baseUnit4: "s",
            imagePath: "projectile_motion_up_velocity",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    projectile_motion_up_time_of_rise() {
        return {
            calculatorType: "e1Ee2De3",
            title: Strings.projectile_motion_up_time_of_rise(),
            description: Strings._time_of_rise_description(),
            element1: "T",
            baseUnit1: "s",
            element2: "v",
            baseUnit2: "m/s",
            element3: "g",
            baseUnit3: "m/s^2",
            imagePath: "projectile_motion_up_time_of_rise",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    projectile_motion_up_height_of_projectile() {
        return {
            calculatorType: "e1Ee2p2D2e3",
            title: Strings.projectile_motion_up_height_of_projectile(),
            description: Strings._height_of_projectile_description(),
            element1: "H",
            baseUnit1: "m",
            element2: "v",
            baseUnit2: "m/s",
            element3: "g",
            baseUnit3: "m/s^2",
            imagePath: "projectile_motion_up_height_of_projectile",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    uniformly_circular_motion_circular_path() {
        return {
            calculatorType: "e1Ee2De3",
            title: Strings.uniformly_circular_motion_circular_path(),
            description: Strings._circular_path_description(),
            element1: "φ",
            baseUnit1: "°",
            element2: "s",
            baseUnit2: "m",
            element3: "r",
            baseUnit3: "m",
            imagePath: "uniform_cicular_motion_circular_path",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    uniformly_circular_motion_angular_velocity1() {
        return {
            calculatorType: "e1Ee2De3",
            title: Strings.uniformly_circular_motion_angular_velocity1(),
            description: Strings._angular_velocity1_description(),
            element1: "ω",
            baseUnit1: "rad/s",
            element2: "Δφ",
            baseUnit2: "°",
            element3: "Δt",
            baseUnit3: "Hz",
            imagePath: "uniform_cicular_motion_angular_velocity1",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    uniformly_circular_motion_angular_velocity2() {
        return {
            calculatorType: "e1E2PIe2",
            title: Strings.uniformly_circular_motion_angular_velocity2(),
            description: Strings._angular_velocity2_description(),
            element1: "ω",
            baseUnit1: "rad/s",
            element2: "f",
            baseUnit2: "Hz",
            imagePath: "uniform_cicular_motion_angular_velocity2",
            element3: null,
            baseUnit3: null,
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    uniformly_circular_motion_velocity1_ucm() {
        return {
            calculatorType: "e1Ee2De3",
            title: Strings.uniformly_circular_motion_velocity1_ucm(),
            description: Strings._velocity1_ucm_description(),
            element1: "v",
            baseUnit1: "m/s",
            element2: "Δs",
            baseUnit2: "m",
            element3: "Δt",
            baseUnit3: "s",
            imagePath: "uniform_cicular_motion_velocity1",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    uniformly_circular_motion_velocity2_ucm() {
        return {
            calculatorType: "e1E2PIe2e3",
            title: Strings.uniformly_circular_motion_velocity2_ucm(),
            description: Strings._velocity2_ucm_description(),
            element1: "v",
            baseUnit1: "m/s",
            element2: "r",
            baseUnit2: "m",
            element3: "f",
            baseUnit3: "Hz",
            imagePath: "uniform_cicular_motion_velocity2",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    uniformly_circular_motion_velocity3_ucm() {
        return {
            calculatorType: "e1Ee2e3",
            title: Strings.uniformly_circular_motion_velocity3_ucm(),
            description: Strings._velocity3_ucm_description(),
            element1: "v",
            baseUnit1: "m/s",
            element2: "ω",
            baseUnit2: "rad/s",
            element3: "r",
            baseUnit3: "m",
            imagePath: "uniform_cicular_motion_velocity3",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    uniformly_circular_motion_period_of_motion() {
        return {
            calculatorType: "e1E2PIDe2",
            title: Strings.uniformly_circular_motion_period_of_motion(),
            description: Strings.period_of_motion_description(),
            element1: "T",
            baseUnit1: "s",
            element2: "ω",
            baseUnit2: "rad/s",
            imagePath: "uniform_cicular_motion_period_motion",
            element3: null,
            baseUnit3: null,
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    uniformly_circular_motion_frequency_of_motion() {
        return {
            calculatorType: "e1E1De2",
            title: Strings.uniformly_circular_motion_frequency_of_motion(),
            description: Strings.frequency_of_motion_description(),
            element1: "f",
            baseUnit1: "Hz",
            element2: "T",
            baseUnit2: "s",
            imagePath: "uniform_cicular_motion_frequency_of_motion",
            element3: null,
            baseUnit3: null,
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    uniformly_circular_motion_acceleration1_ucm() {
        return {
            calculatorType: "e1Ee2e3P2",
            title: Strings.uniformly_circular_motion_acceleration1_ucm(),
            description: Strings._acceleration1_ucm_description(),
            element1: "a",
            baseUnit1: "m/s^2",
            element2: "r",
            baseUnit2: "m",
            element3: "ω",
            baseUnit3: "rad/s",
            imagePath: "uniform_cicular_motion_acceleration",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    moment_of_inertia_fixed_point() {
        return {
            calculatorType: "e1Ee2e3P2",
            title: Strings.moment_of_inertia_fixed_point(),
            description: Strings._fixed_point_description(),
            element1: "I",
            baseUnit1: "kgm^2",
            element2: "m",
            baseUnit2: "kg",
            element3: "r",
            baseUnit3: "m",
            imagePath: "moment_of_ineria_fixed_point",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    moment_of_inertia_solid_cylinder() {
        return {
            calculatorType: "e1E05e2e3P2",
            title: Strings.moment_of_inertia_solid_cylinder(),
            description: Strings._solid_cylinder_description(),
            element1: "I",
            baseUnit1: "kgm^2",
            element2: "m",
            baseUnit2: "kg",
            element3: "r",
            baseUnit3: "m",
            imagePath: "moment_of_ineria_solid_cylinder",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    moment_of_inertia_solid_sphere() {
        return {
            calculatorType: "e1E04e2e3p2",
            title: Strings.moment_of_inertia_solid_sphere(),
            description: Strings._solid_sphere_description(),
            element1: "I",
            baseUnit1: "kgm^2",
            element2: "m",
            baseUnit2: "kg",
            element3: "r",
            baseUnit3: "m",
            imagePath: "moment_of_ineria_solid_sphere",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    simple_machine_lever() {
        return {
            calculatorType: "e1e2Ee3e4",
            title: Strings.simple_machine_lever(),
            description: Strings._lever_description(),
            element1: "F1",
            baseUnit1: "N",
            element2: "a",
            baseUnit2: "m",
            element3: "F2",
            baseUnit3: "N",
            element4: "b",
            baseUnit4: "m",
            imagePath: "simple_machine_lever",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    simple_machine_pulley() {
        return {
            calculatorType: "e1E05e2",
            title: Strings.simple_machine_pulley(),
            description: Strings._pulley_description(),
            element1: "F1",
            baseUnit1: "N",
            element2: "F2",
            baseUnit2: "N",
            imagePath: "simple_machine_pulley",
            element3: null,
            baseUnit3: null,
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    simple_machine_wheel_and_axle() {
        return {
            calculatorType: "e1e2Ee3e4",
            title: Strings.simple_machine_wheel_and_axle(),
            description: Strings._wheel_and_axle_description(),
            element1: "F1",
            baseUnit1: "N",
            element2: "R",
            baseUnit2: "m",
            element3: "F2",
            baseUnit3: "N",
            element4: "r",
            baseUnit4: "m",
            imagePath: "simple_machine_wheel_and_axle",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    simple_machine_inclined_plane1() {
        return {
            calculatorType: "e1Ee2e3sine4",
            title: Strings.simple_machine_inclined_plane1(),
            description: Strings._inclined_plane1_description(),
            baseUnit1: "N",
            element2: "m",
            baseUnit2: "kg",
            element3: "g",
            baseUnit3: "m/s^2",
            element4: "α",
            baseUnit4: "°",
            imagePath: "simple_machine_inclened_plane",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    simple_machine_inclined_plane2() {
        return {
            calculatorType: "e1Ee2e3cose4",
            title: Strings.simple_machine_inclined_plane2(),
            description: Strings._inclined_plane2_description(),
            element1: "F",
            baseUnit1: "N",
            element2: "m",
            baseUnit2: "kg",
            element3: "g",
            baseUnit3: "m/s^2",
            element4: "α",
            baseUnit4: "°",
            imagePath: "simple_machine_inclened_plane2",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    gravitational_field_intensity_above_planets_surface() {
        return {
            calculatorType: "e1Ee2e3De4ae5P2",
            title: Strings.gravitational_field_intensity_above_planets_surface(),
            description: Strings._above_planets_surface_description(),
            element1: "K",
            baseUnit1: "N/kg",
            element2: "ϰ",
            baseUnit2: "m^3kg^-1s^-2",
            element3: "M",
            baseUnit3: "kg",
            element4: "R",
            baseUnit4: "m",
            element5: "h",
            baseUnit5: "m",
            imagePath: "above_planet_surface",
            baseUnit6: null,
            element6: null
        }
    }

    gravitational_field_intensity_on_planets_surface() {
        return {
            calculatorType: "e1Ee2e3De4P2",
            title: Strings.gravitational_field_intensity_on_planets_surface(),
            description: Strings._on_planets_surface_description(),
            element1: "K",
            baseUnit1: "N/kg",
            element2: "ϰ",
            baseUnit2: "m^3kg^-1s^-2",
            element3: "M",
            baseUnit3: "kg",
            element4: "R",
            baseUnit4: "m",
            imagePath: "on_oplanets_surface",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }
}

export default new Mechanics();
