import React, { Component } from "react";
import { Link } from "react-router-dom";

import Chemistry from "../helpers/chemistry.helper";
import Physics from "../helpers/physics.helper";
import ElectrictyAndMagnetism from "../helpers/electricity-and-magnetismus.physics.helper";
import Hydromechanics from "../helpers/hydromechanics.physics.helper";
import MechanicalVibrationWavesAndAcoustic from "../helpers/mechanical-vibration-waves-acoustic.physics.helper";
import Mechanics from "../helpers/mechanics.physics.helper";
import MolecularPhysicsAndThermodynamic from "../helpers/molecular-physics-thermodynamic.physics.helper";
import Optics from "../helpers/optics.physics.helper";
import QuantumPhysics from "../helpers/quantum-physics.physics.helper";
import TheSpecialTheoryOfRelativity from "../helpers/special-theory-of-relativity.physics.helper";
import reportVisitService from "../reporting-visit/report-visit.service";

export default class List extends Component {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.searching = this.searching.bind(this);
    this.clearSearch = this.clearSearch.bind(this);

    this.state = {
      title: "",
      items: [],
      backupItems: [],
      oldPath: "",
      searchWord: "",
    };
  }

  componentDidMount() {
    this.getData();
    reportVisitService.report(window.location.pathname, document.title);
  }

  componentDidUpdate() {
    this.getData();
  }

  searching(e) {
    var searchWords = e.target.value.toLowerCase();
    var tempItems = [];

    if (searchWords.length > 0) {
      this.state.backupItems.map((item) => {
        if (item.name.toLowerCase().includes(searchWords)) {
          tempItems.push(item);
        }
      });

      this.setState({
        items: tempItems,
        searchWord: searchWords,
      });
    } else {
      this.setState({
        items: this.state.backupItems,
        searchWord: "",
      });
    }
  }

  clearSearch() {
    this.setState({
      items: this.state.backupItems,
      searchWord: "",
    });
  }

  getData() {
    const path = window.location.pathname;
    if (this.state.oldPath !== path) {
      if (path.endsWith("/")) path = path.substring(0, path.length - 1);

      var data;
      switch (path) {
        case "/chemistry":
          data = Chemistry.getFormulas();
          break;
        case "/physics/electricity-and-magnetismus":
          data = ElectrictyAndMagnetism.getFormulas();
          break;
        case "/physics/hydromechanics":
          data = Hydromechanics.getFormulas();
          break;
        case "/physics/mechanical-vibration-waves-acoustic":
          data = MechanicalVibrationWavesAndAcoustic.getFormulas();
          break;
        case "/physics/mechanics":
          data = Mechanics.getFormulas();
          break;
        case "/physics/molecular-physics-thermodynamic":
          data = MolecularPhysicsAndThermodynamic.getFormulas();
          break;
        case "/physics/optics":
          data = Optics.getFormulas();
          break;
        case "/physics/quantum-physics":
          data = QuantumPhysics.getFormulas();
          break;
        case "/physics/special-theory-of-relativity":
          data = TheSpecialTheoryOfRelativity.getFormulas();
          break;
        case "/physics":
          data = Physics.getCategories();
          break;
      }

      var categoryName = "Physics";
      if (path.includes("chemistry")) {
        categoryName = "Chemistry";
      }

      document.title = data.title + " - " + categoryName + " | Formula Solver";

      this.props.setPageTitle(categoryName);

      this.setState({
        title: data.title,
        items: data.items,
        backupItems: data.items,
        oldPath: path,
      });
    }
  }

  render() {
    return (
      <div className="list_grid">
        <h1 className="title_list">{this.state.title}</h1>

        <div className="div_search">
          <input
            placeholder="Type to search..."
            className="input_search"
            type="text"
            onChange={this.searching}
            value={this.state.searchWord}
          />
          <button
            className="action_button action_close_search"
            title="Clear search"
            onClick={this.clearSearch}
          />
        </div>

        {this.state.items && this.state.items.length > 0 ? (
          <div>
            {this.state.items.map((item) => (
              <div>
                <Link to={item.link}>{item.name}</Link>
                <br />
              </div>
            ))}
          </div>
        ) : (
          <label className="no_data">No items</label>
        )}
      </div>
    );
  }
}
