import Strings from "./strings.helper";

class Hydromechanics {
    getFormulas() {
        const titleString = "Hydromechanics";
        var namesList = [];
        var linksList = [];
        var itemsList = [];

        linksList.push("/physics/hydromechanics/pressure");
        namesList.push("Pressure");

        linksList.push("/physics/hydromechanics/compressive-force");
        namesList.push("Compressive force");

        linksList.push("/physics/hydromechanics/hydraulic-press");
        namesList.push("Hydraulic press");

        linksList.push("/physics/hydromechanics/hydraulic-pressure");
        namesList.push("Hydraulic pressure");

        linksList.push("/physics/hydromechanics/hydraulic-strength");
        namesList.push("Hydrostatic strength");

        linksList.push("/physics/hydromechanics/hydrostatic-buoyancy");
        namesList.push("Hydrostatic buoyancy");

        linksList.push("/physics/hydromechanics/liquid-velocity");
        namesList.push("Liquid velocity");

        linksList.push("/physics/hydromechanics/resistance-force");
        namesList.push("Resistance force");

        linksList.map((linkT, index) => {
            var item = {
                link: linkT,
                name: namesList[index]
            }

            itemsList.push(item);
        });

        return {
            title: titleString,
            items: itemsList
        }
    }

    getFormulaParams(formulaName) {
        switch (formulaName) {
            case "pressure":
               return this.pressure();
            case "compressive-force":
               return this.compressive_force();
            case "hydraulic-press":
               return this.hydraulic_press();
            case "hydraulic-pressure":
               return this.hydraulic_pressure();
            case "hydraulic-strength":
               return this.hydraulic_strength();
            case "hydrostatic-buoyancy":
               return this.hydrostatic_buoyancy();
            case "liquid-velocity":
               return this.liquid_velocity();
            case "resistance-force":
               return this.resistance_force();
        }
    }

    pressure() {
        return {
            calculatorType: "e1Ee2De3",
            imagePath: "pressure",
            title: Strings.pressure(),
            description: Strings.pressure_description(),
            element1: "p",
            baseUnit1: "Pa",
            element2: "F",
            baseUnit2: "N",
            element3: "S",
            baseUnit3: "m^2",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    compressive_force() {
        return {
            calculatorType: "e1Ee2e3",
            imagePath: "compressive_force",
            title: Strings.compressive_force(),
            description: Strings.compressive_force_description(),
            element1: "F",
            baseUnit1: "N",
            element2: "p",
            baseUnit2: "Pa",
            element3: "S",
            baseUnit3: "m^2",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    hydraulic_press() {
        return {
            calculatorType: "e1Ee2e3De4",
            imagePath: "hydraulic_press",
            title: Strings.hydraulic_press(),
            description: Strings.hydraulic_press_description(),
            element1: "F1",
            baseUnit1: "N",
            element2: "F2",
            baseUnit2: "N",
            element3: "S2",
            baseUnit3: "m^2",
            element4: "S1",
            baseUnit4: "m^2",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    hydraulic_pressure() {
        return {
            calculatorType: "e1Ee2e3e4",
            imagePath: "hydraulic_pressure",
            title: Strings.hydraulic_pressure(),
            description: Strings.hydraulic_pressure_description(),
            element1: "p",
            baseUnit1: "Pa",
            element2: "ρ",
            baseUnit2: "kg/m^3",
            element3: "g",
            baseUnit3: "m/s^2",
            element4: "h",
            baseUnit4: "m",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    hydraulic_strength() {
        return {
            calculatorType: "e1Ee2e3e4e5",
            imagePath: "hydraulic_strength",
            title: Strings.hydrostatic_strength(),
            description: Strings.hydrostatic_strength_description(),
            element1: "F",
            baseUnit1: "N",
            element2: "ρ",
            baseUnit2: "kg/m^3",
            element3: "g",
            baseUnit3: "m/s^2",
            element4: "h",
            baseUnit4: "m",
            element5: "S",
            baseUnit5: "m^2",
            baseUnit6: null,
            element6: null
        }
    }

    hydrostatic_buoyancy() {
        return {
            calculatorType: "e1Ee2e3e4",
            imagePath: "hydrostatic_buoyancy",
            title: Strings.hydrostatic_buoyancy(),
            description: Strings.hydrostatic_buoyancy_description(),
            element1: Strings.buoyancy(),
            baseUnit1: "N",
            element2: "V",
            baseUnit2: "l",
            element3: "ρ",
            baseUnit3: "kg/m^3",
            element4: "g",
            baseUnit4: "m/s^2",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    liquid_velocity() {
        return {
            calculatorType: "e1ESQRT2e2e3",
            imagePath: "liquid_velocity",
            title: Strings.liquid_velocity(),
            description: Strings.liquid_velocity_description(),
            element1: "v",
            baseUnit1: "m/s",
            element2: "h",
            baseUnit2: "m",
            element3: "g",
            baseUnit3: "m/s^2",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null

        }
    }

    resistance_force() {
        return {
            calculatorType: "e1Ee205e3e4e5",
            imagePath: "resistance_force",
            title: Strings.resistance_force(),
            description: Strings.resistance_force_description(),
            element1: "F",
            baseUnit1: "N",
            element2: "C",
            baseUnit2: " ",
            element3: "ρ",
            baseUnit3: "kg/m^3",
            element4: "S",
            baseUnit4: "m^2",
            element5: "v",
            baseUnit5: "m/s",
            baseUnit6: null,
            element6: null
        }
    }
}

export default new Hydromechanics();
