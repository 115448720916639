class Chemistry {
    getFormulas() {
        const titleString = "Chemistry";
        var namesList = [];
        var linksList = [];
        var itemsList = [];

        linksList.push("/chemistry/mass-fraction");
        namesList.push("Mass fraction");

        linksList.push("/chemistry/molecular-weight-and-amount-of-substance");
        namesList.push("Molecular weight and amount of substance");

        linksList.push("/chemistry/concentration");
        namesList.push("Concentration");

        linksList.push("/chemistry/molar-volume");
        namesList.push("Molar volume");

        linksList.push("/chemistry/substance-amount");
        namesList.push("Substance amount");

        linksList.push("/chemistry/substance-amount-with-Avogadros-number");
        namesList.push("Substance amount with Avogadro´s number");

        linksList.push("/chemistry/mixing-equation");
        namesList.push("Mixing equation");

        linksList.push("/chemistry/ideal-gas-law");
        namesList.push("Ideal gas law");

        linksList.map((linkT, index) => {
            var item = {
                link: linkT,
                name: namesList[index]
            }

            itemsList.push(item);
        });

        return {
            title: titleString,
            items: itemsList
        }

    }

    getFormulaParams(formulaName) {
        switch (formulaName) {
            case "mass-fraction":
                return this.massFraction()
            case "molecular-weight-and-amount-of-substance":
                return this.molecularWeightAndAmountOfSubstance()
            case "concentration":
                return this.concentration()
            case "molar-volume":
                return this.molarVolume()
            case "substance-amount":
                return this.substanceAmount()
            case "substance-amount-with-Avogadros-number":
                return this.substanceAmountWithAvogadrosNumber()
            case "mixing-equation":
                return this.mixingEquation()
            case "ideal-gas-law":
                return this.idealGasLaw()
        }
    }

    massFraction() {
        return {
            calculatorType: "D3",
            title: "Mass fraction",
            description: "wa - mass fraction\nma - weight substances in mixture\nms - weight of mixture",
            element1: "Wa",
            baseUnit1: "%",
            element2: "mA",
            baseUnit2: "kg",
            element3: "mS",
            baseUnit3: "kg",
            imagePath: "mass_fraction",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    molecularWeightAndAmountOfSubstance() {
        return {
            calculatorType: "M3",
            title: "Molecular weight and amount of substance",
            description: "m - weight of substance\nn - substance amount\nM - molecular weight",
            element1: "m",
            baseUnit1: "kg",
            element2: "n",
            baseUnit2: "mol",
            element3: "M",
            baseUnit3: "g/mol",
            imagePath: "molecular_weight_and_amount",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    concentration() {
        return {
            calculatorType: "D3",
            title: "Concentration",
            description: "c - molar concentration\nn - substance amount\nV - volume",
            element1: "c",
            baseUnit1: "mol/l",
            element2: "n",
            baseUnit2: "mol",
            element3: "V",
            baseUnit3: "l",
            imagePath: "concentration",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    molarVolume() {
        return {
            calculatorType: "D3",
            title: "Molar volume",
            description: "n - substance amount\nV - volume\nVm - molar volume",
            element1: "Vm",
            baseUnit1: "l/mol",
            element2: "V",
            baseUnit2: "l",
            element3: "n",
            baseUnit3: "mol",
            imagePath: "molar_volume",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    substanceAmount() {
        return {
            calculatorType: "D3",
            title: "Substance amount",
            description: "n - substance amount\nV - volume\nVm - molar volume",
            element1: "n",
            baseUnit1: "mol",
            element2: "V",
            baseUnit2: "l",
            element3: "Vm",
            baseUnit3: "l/mol",
            imagePath: "substance_amount",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    substanceAmountWithAvogadrosNumber() {
        return {
            calculatorType: "D3",
            title: "Substance amount with Avogadro´s number",
            description: "n - substance amount\nNa - Avogadro's number\nN - number of particles",
            element1: "n",
            baseUnit1: "mol",
            element2: "N",
            baseUnit2: " ",
            element3: "Na",
            baseUnit3: "mol^-1",
            imagePath: "substance_amount__",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    mixingEquation() {
        return {
            calculatorType: "A5",
            title: "Mixing equation",
            description: "m1, m2, m3 - solution weight\nw1, w2, w3 - mass fractions of the solute in the solution",
            element1: "m1",
            baseUnit1: "kg",
            element2: "w1",
            baseUnit2: "%",
            element3: "m2",
            baseUnit3: "kg",
            element4: "w2",
            baseUnit4: "%",
            element5: "m3",
            baseUnit5: "kg",
            baseUnit6: "%",
            element6: "w3",
            imagePath: "mixing_equation"
        }
    }

    idealGasLaw() {
        return {
            calculatorType: "M5",
            title: "Ideal gas law",
            description: "p - pressure\nV - volume\nn - substance amount\nT - thermodynamic temperature\nR - molar gas constant",
            element1: "p",
            baseUnit1: "Pa",
            element2: "V",
            baseUnit2: "l",
            element3: "n",
            baseUnit3: "mol",
            element4: "R",
            baseUnit4: "J*mol^-1*K^-1",
            element5: "T",
            baseUnit5: "K",
            imagePath: "ideal_gas_law",
            baseUnit6: null,
            element6: null
        }
    }
}

export default new Chemistry();
