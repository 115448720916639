class Strings {

    alert() {
        return "Attention!";

    }
    alert_message() {
        return "Determine what you want to calculate.";
    }

    alert_message_procentage() {
        return "You must enter a value less than 1";

    }
    alert_message_percentage() {
        return "You must have a value greater than 1 for the mass fraction!";

    }
    alert_message_constant() {
        return "You have chosen a constant!";

    }
    alert_gravity_constant() {
        return "You have chosen a gravity constant!";

    }
    alert_newton_gravity_constant() {
        return "You have chosen a Newton's gravity constant!";

    }
    stefan_boltzmann_constant() {
        return "You have chosen a Stefan-Boltzmann constant!";

    }
    planc_value() {
        return "You have chosen a Planc value!";

    }
    nothing() {
        return " ";

    }
    weins_constant() {
        return "You choose Wein's constant (2,8977729 mmK)!";

    }
    constant() {
        return "You choose constant!";

    }
    light() {
        return "You choose speed of light (299 792 458 m/s)!";

    }
    result() {
        return "Result";

    }
    name_of_category() {
        return "This is only name of category!";

    }
    type_to_search() {
        return "Type to search";


    }
    enter() {
        return "Enter value of element: ";

        // Tabs

    }
    tabPhysics() {
        return "Physics";

    }
    tabChemistry() {
        return "Chemistry";

        // Chemistry 

    }
    mass_fraction() {
        return "Mass fraction";

    }
    molecular_weight_and_amount_of_substance() {
        return "Molecular weight and amount of substance";

    }
    concentration() {
        return "Concentration";

    }
    molar_volume() {
        return "Molar volume";

    }
    substance_amount() {
        return "Substance amount";

    }
    substance_amount_with_Avogadros_number() {
        return "Substance amount with Avogadro´s number";

    }
    mixing_equation() {
        return "Mixing equation";

    }
    ideal_gas_law() {
        return "Ideal gas law";


    }
    mass_fraction_description() {
        return "wa - mass fraction\nma - weight substances in mixture\nms - weight of mixture";

    }
    molecular_weight_and_amount_of_substance_description() {
        return "m - weight of substance\nn - substance amount\nM - molecular weight";

    }
    concentration_description() {
        return "c - molar concentration\nn - substance amount\nV - volume";

    }
    molar_volume_description() {
        return "n - substance amount\nV - volume\nVm - molar volume";

    }
    substance_amount_description() {
        return "n - substance amount\nV - volume\nVm - molar volume";

    }
    substance_amount_with_Avogadros_number_description() {
        return "n - substance amount\nNa - Avogadro's number\nN - number of particles";

    }
    mixing_equation_description() {
        return "m1, m2, m3 - solution weight\nw1, w2, w3 - mass fractions of the solute in the solution";

    }
    ideal_gas_law_description() {
        return "p - pressure\nV - volume\nn - substance amount\nT - thermodynamic temperature\nR - molar gas constant";



    }
    elements() {
        return "Elements";

    }
    nomenclature_of_compounds() {
        return "Nomenclature of compounds";
        // Physics 


    }
    ElectricityAndMagnetism() {
        return "Electricity and magnetism";

    }
    Hydromechanics() {
        return "Hydromechanics";

    }
    MechanicalOscillationsAndWavesAndAcoustics() {
        return "Mechanics oscillation, wave and acoustics";

    }
    Mechanics() {
        return "Mechanics";

    }
    MolecularPhysicsAndThermodynamics() {
        return "Molecular physics and thermodynamics";

    }
    Optics() {
        return "Optics";

    }
    QuantumPhysics() {
        return "Quantum physics";

    }
    SpecialTheoryOfRelativity() {
        return "Special theory of Relativity";


        // Mechanics 


    }
    density_() {
        return "Density";


    }
    uniform_motion() {
        return "Uniform motion";

    }
    uniform_motion_velocity() {
        return "Velocity of uniform motion";

    }
    uniform_motion_path() {
        return "Path of uniform motion";

    }
    _velocity() {
        return "- velocity";

    }
    _path() {
        return "- path";


    }
    linear_motion() {
        return "Linear motion";

    }
    linear_motion_acceleration1() {
        return "Acceleration linear motion";

    }
    linear_motion_acceleration2() {
        return "Linear motion - acceleration when starting from rest";

    }
    linear_motion_velocity1() {
        return "Velocity of linear motion";

    }
    linear_motion_velocity2() {
        return "Linear motion - velocity when starting from rest 1";

    }
    linear_motion_velocity3() {
        return "Linear motion - velocity when starting from rest 2";

    }
    linear_motion_path1() {
        return "Linear motion - path when starting from rest 1";

    }
    linear_motion_path2() {
        return "Linear motion - path when starting from rest 2";

    }
    _acceleration1() {
        return "- acceleration";

    }
    _acceleration2() {
        return "- acceleration when starting from rest";

    }
    _velocity1() {
        return "- velocity";

    }
    _velocity2() {
        return "- velocity when starting from rest 1";

    }
    _velocity3() {
        return "- velocity when starting from rest 2";

    }
    _path1() {
        return "- path when starting from rest 1";

    }
    _path2() {
        return "- path when starting from rest 2";


    }
    free_fall() {
        return "Free Fall";

    }
    _impact_velocity_from_given_height() {
        return "- impact velocity from given height h";

    }
    _velocity_ff() {
        return "- velocity";

    }
    _path_ff() {
        return "- path";


    }
    free_fall_impact_velocity_from_given_height() {
        return "Impact velocity from given height h of free fall";

    }
    free_fall_velocity_ff() {
        return "Velocity of free fall";

    }
    free_fall_path_ff() {
        return "Path of free fall";


    }
    projectile_motion_up() {
        return "Projectile motion up";

    }
    projectile_motion_up_velocity_pmu() {
        return "Velocity";

    }
    projectile_motion_up_time_of_rise() {
        return "Time of rise";

    }
    projectile_motion_up_height_of_projectile() {
        return "Height of projectile";


    }
    _velocity_pmu() {
        return "- velocity";

    }
    _time_of_rise() {
        return "- time of rise";

    }
    _height_of_projectile() {
        return "- height of projectile";


    }
    uniformly_circular_motion() {
        return "Uniform circular motion";

    }
    uniformly_circular_motion_circular_path() {
        return "Circular path of uniform circular motion";

    }
    uniformly_circular_motion_angular_velocity1() {
        return "Angular velocity of uniform circular motion";

    }
    uniformly_circular_motion_angular_velocity2() {
        return "Angular velocity of uniform circular motion";

    }
    uniformly_circular_motion_velocity1_ucm() {
        return "Velocity of uniform circular motion";

    }
    uniformly_circular_motion_velocity2_ucm() {
        return "Velocity of uniform circular motion";

    }
    uniformly_circular_motion_velocity3_ucm() {
        return "Velocity of uniform circular motion";

    }
    uniformly_circular_motion_period_of_motion() {
        return "Period of motion of uniform circular motion";

    }
    uniformly_circular_motion_frequency_of_motion() {
        return "Frequency of motion of uniform circular motion";

    }
    uniformly_circular_motion_acceleration1_ucm() {
        return "Acceleration of uniform circular motion";

    }
    uniformly_circular_motion_acceleration2_ucm() {
        return "Acceleration of uniform circular motion";


    }
    _circular_path() {
        return "- circular path";

    }
    _angular_velocity1() {
        return "- angular velocity 1";

    }
    _angular_velocity2() {
        return "- angular velocity 2";

    }
    _velocity1_ucm() {
        return "- velocity 1";

    }
    _velocity2_ucm() {
        return "- velocity 2";

    }
    _velocity3_ucm() {
        return "- velocity 3";

    }
    _period_of_motion() {
        return "- period of motion";

    }
    _frequency_of_motion() {
        return "- frequency of motion";

    }
    _acceleration1_ucm() {
        return "- acceleration 1";

    }
    _acceleration2_ucm() {
        return "- acceleration 2";


    }
    momentum() {
        return "Momentum";

    }
    impulse_of_force() {
        return "Impulse of force";

    }
    second_law_of_motion1() {
        return "Second law of motion";

    }
    second_law_of_motion2() {
        return "Second law of motion if m is constant";

    }
    gravitational_force() {
        return "Gravitational force 1";

    }
    centripetal_force1() {
        return "Centripetal force 1";

    }
    centripetal_force2() {
        return "Centripetal force 2";

    }
    the_inclination_of_the_road_in_a_curve() {
        return "The inclination of the road in a curve";

    }
    moment_of_force() {
        return "Moment of force";

    }
    balance_the_two_forces() {
        return "Balance the two forces";

    }
    simple_couple() {
        return "Simple couple";

    }
    mechanical_work1() {
        return "Mechanical work 1";

    }
    mechanical_work2() {
        return "Mechanical work 2";

    }
    mechanical_work3() {
        return "Mechanical work if cos α = 1";

    }
    kinetic_energy() {
        return "Kinetic energy";

    }
    potential_energy() {
        return "Potential energy";

    }
    energy_conservation1() {
        return "Energy conservation 1";

    }
    energy_conservation2() {
        return "Energy conservation 2";

    }
    power() {
        return "Power";

    }
    power_with_f() {
        return "Power with force";

    }
    efficiency_of_work() {
        return "Efficiency of work";

    }
    efficiency_of_power() {
        return "Efficiency of power";


    }
    moment_of_inertia() {
        return "Moment of inertia";

    }
    moment_of_inertia_fixed_point() {
        return "Moment of inertia of fixed point";

    }
    moment_of_inertia_solid_cylinder() {
        return "Moment of inertia of solid cylinder";

    }
    moment_of_inertia_solid_sphere() {
        return "Moment of inertia of solid sphere";


    }
    _fixed_point() {
        return "- fixed point";

    }
    _solid_cylinder() {
        return "- solid cylinder";

    }
    _solid_sphere() {
        return "- solid sphere";


    }
    mass_moment_of_inertia() {
        return "Mass moment of inertia";// oddělené od momentu setrvačnosti 

    }
    angular_kinetic_energy() {
        return "Angular kinetic energy";

    }
    friction() {
        return "Friction";

    }
    rolling_resistance() {
        return "Rolling Resistance";


    }
    simple_machine() {
        return "Simple machine";

    }
    simple_machine_lever() {
        return "Lever";

    }
    simple_machine_pulley() {
        return "Pulley";

    }
    simple_machine_wheel_and_axle() {
        return "Wheel and axle";

    }
    simple_machine_inclined_plane1() {
        return "Inclined plane";

    }
    simple_machine_inclined_plane2() {
        return "Inclined plane";


    }
    _lever() {
        return "- lever";

    }
    _pulley() {
        return "- pulley";

    }
    _wheel_and_axle() {
        return "- wheel and axle";

    }
    _inclined_plane1() {
        return "- inclined plane 1";

    }
    _inclined_plane2() {
        return "- inclined plane 2";


    }
    weighing() {
        return "Weighing";

    }
    newtons_law_of_universal_gravitation() {
        return "Newton's law of universal gravitation";

    }
    gravitational_field_intensity() {
        return "Gravitational field intensity";

    }
    _above_planets_surface() {
        return "- above planet's surface";

    }
    _on_planets_surface() {
        return "- on planet's surface";

    }
    gravitational_field_intensity_above_planets_surface() {
        return "Gravitational field intensity above planet's surface";

    }
    gravitational_field_intensity_on_planets_surface() {
        return "Gravitational field intensity on planet's surface";


    }
    work_done_by_gravitational_field() {
        return "Work done by gravitational field";

    }
    gravitational_potential_energy() {
        return "Gravitational potential energy";

        // description of Mechanics 

    }
    density__description() {
        return "ρ - density\nm - mass\nV - volume";

    }
    _velocity_description() {
        return "v - velocity\ns - length of the path travelled until time t\nt - time";

    }
    _path_description() {
        return "v - velocity\ns - length of the path travelled until time t\nt - time";

    }
    _acceleration1_description() {
        return "a - acceleration\nv - velocity\nv0 - initial velocity\nt - time";

    }
    _acceleration2_description() {
        return "a - acceleration\nv - velocity\nt - time";

    }
    _velocity1_description() {
        return "v - velocity\nv0 - initial velocity\na - acceleration\nt - time";

    }
    _velocity2_description() {
        return "v - velocity\na - acceleration\nt - time";

    }
    _velocity3_description() {
        return "v - velocity\na - acceleration\ns - length of the path travelled until time t";

    }
    _path1_description() {
        return "a - acceleration\ns - length of the path travelled until time t\nt - time";

    }
    _path2_description() {
        return "v - velocity\ns - length of the path travelled until time t\nt - time";

    }
    _impact_velocity_from_given_height_description() {
        return "v - impact velocity\ng - gravitational acceleration\nh - height";

    }
    _velocity_ff_description() {
        return "v - velocity\ng - gravitational acceleration\nt - time";

    }
    _path_ff_description() {
        return "s - length of the path travelled until time t\ng - gravitational acceleration\nt - time";

    }
    _velocity_pmu_description() {
        return "v - velocity\nv0 - initial velocity\ng - gravitational acceleration\nt - time";

    }
    _time_of_rise_description() {
        return "T - time of rise\nv - initial velocity\ng - gravitational acceleration";

    }
    _height_of_projectile_description() {
        return "H - height of projectile\nv - initial velocity\ng - gravitational acceleration";

    }
    _circular_path_description() {
        return "φ - circular path\ns - length\nr - radius";

    }
    _angular_velocity1_description() {
        return "ω - angular velocity\nΔφ - difference of circular path\nΔt - difference of time";

    }
    _angular_velocity2_description() {
        return "ω - angular velocity\nf - frequency";

    }
    _velocity1_ucm_description() {
        return "v - velocity\nΔs - difference of length\nΔt - difference of time";

    }
    _velocity2_ucm_description() {
        return "v - velocity\nr - radius\nf - frequency";

    }
    _velocity3_ucm_description() {
        return "v - velocity\nω - angular velocity\nr - radius";

    }
    period_of_motion_description() {
        return "T - time period\nω - angular velocity";

    }
    frequency_of_motion_description() {
        return "f - frequency\nT - time period";

    }
    _acceleration1_ucm_description() {
        return "a - acceleration\nω - angular velocity\nr - radius";

    }
    _acceleration2_ucm_description() {
        return "a - acceleration\nv - velocity\nr - radius";

    }
    momentum_description() {
        return "p - momentum\nm - mass\nv - velocity";

    }
    impulse_of_force_description() {
        return "I - impulse of force\nF - force\nΔt - difference of time";

    }
    second_law_of_motion1_description() {
        return "F - force\nΔp - difference of impulse of force\nΔt - difference of time";

    }
    second_law_of_motion2_description() {
        return "F - force\nm - mass\na - acceleration";

    }
    gravitational_force1_description() {
        return "Fg - gravitational force\nm - mass\ng - gravitational acceleration";

    }
    centripetal_force1_description() {
        return "F - centripetal force\nm - mass\nω - angular velocity\nr - radius";

    }
    centripetal_force2_description() {
        return "F - centripetal force\nm - mass\nv - velocity\nr - radius";

    }
    the_inclination_of_the_road_in_a_curve_description() {
        return "α - inclination of road\nv - velocity\ng - gravitational acceleration\nr - radius of curve";

    }
    moment_of_force_description() {
        return "M - moment\nr - radius\nF - force";

    }
    balance_the_two_forces_description() {
        return "r1, r2 - arm of the force\nF1, F2 - force";

    }
    simple_couple_description() {
        return "D - torque\nF - force\nd - the arm of the couple";

    }
    mechanical_work1_description() {
        return "W - work\nF - force\ns - length\nα - angle";

    }
    mechanical_work2_description() {
        return "W - work\nP - power\nt - time";

    }
    mechanical_work3_description() {
        return "W - work\nF - force\ns - length";

    }
    kinetic_energy_description() {
        return "E - kinetic energy\nm - mass\nv - velocity";

    }
    potential_energy_description() {
        return "E - potential energy\nm - mass\ng - gravitational acceleration\nh - height";

    }
    energy_conservation1_description() {
        return "Ep - potential energy\nEk - kinetic energy\nE - energy conservation";

    }
    energy_conservation2_description() {
        return "m - mass\ng - gravitational acceleration\nh - height\nv - velocity\nE - energy conservation";

    }
    power_description() {
        return "P - power\nW - work\nt - time";

    }
    power_with_f_description() {
        return "P - power\nF - force\nv - velocity";

    }
    efficiency_of_work_description() {
        return "η - efficiency\nW' - drawn off work\nW - supplied work";

    }
    efficiency_of_power_description() {
        return "η - efficiency\nP' - drawn off power\nP - supplied power";

    }
    _fixed_point_description() {
        return "I - inertia\nm - mass\nr - distance";

    }
    _solid_cylinder_description() {
        return "I - inertia\nm - mass\nr - distance";

    }
    _solid_sphere_description() {
        return "I - inertia\nm - mass\nr - distance";

    }
    mass_moment_of_inertia_description() {
        return "I - inertia\nI0 - initial inertia m - mass\nr - distance";// oddělené od momentu setrvačnosti 

    }
    angular_kinetic_energy_description() {
        return "E - kinetic energy\nI - inertia\nω - angular velocity";

    }
    friction_description() {
        return "F - friction force\nf - coefficient of friction\nFn - normal force";

    }
    rolling_resistance_description() {
        return "F - force of rolling resistance\nξ - shoulder of rolling resistance\nFn - normal force\nr - distance";

    }
    _lever_description() {
        return "F1, F2 - force\na - shoulder of F1\nb - shoulder of F2 ";

    }
    _pulley_description() {
        return "F1, F2 - force";

    }
    _wheel_and_axle_description() {
        return "F1, F2 - force\nr - radius of smaller wheel\nR - radius of bigger wheel";

    }
    _inclined_plane1_description() {
        return "F - force\nm - mass\ng - gravitational acceleration\nα - angle";

    }
    _inclined_plane2_description() {
        return "F - pressure force\nm - mass\ng - gravitational acceleration\nα - angle";

    }
    weighing_description() {
        return "M, m1, m2 - mass";

    }
    newtons_law_of_universal_gravitation_description() {
        return "Fg - gravitational force\nϰ - gravitational constant\nm1, m2 - mass\nr - distance of both fixed points";

    }
    gravitational_field_intensity_description() {
        return "K - gravitational field intensity\nFg - gravitational force\nm - mass of fixed point";

    }
    _above_planets_surface_description() {
        return "K - gravitational field intensity\nm - mass of fixed point\nR - radius of planet\nh - height above the planet";

    }
    _on_planets_surface_description() {
        return "K - gravitational field intensity\nm - mass of fixed point\nR - radius of planet";

    }
    work_done_by_gravitational_field_description() {
        return "W - work\nm - mass\nK - gravitational field intensity\nh1, h2 - height";

    }
    gravitational_potential_energy_description() {
        return "E - gravitational potential energy\nm - mass\nK - gravitational field intensity\nh1 - height";


        // Hydromechanics 


    }
    pressure() {
        return "Pressure";

    }
    compressive_force() {
        return "Compressive force";

    }
    hydraulic_press() {
        return "Hydraulic press";

    }
    hydraulic_pressure() {
        return "Hydraulic pressure";

    }
    hydrostatic_strength() {
        return "Hydrostatic strength";

    }
    hydrostatic_buoyancy() {
        return "Hydrostatic buoyancy";

    }
    liquid_velocity() {
        return "Liquid velocity";

    }
    resistance_force() {
        return "Resistance force";

        // description of Hydromechanics

    }
    pressure_description() {
        return "p - pressure\nF - force\nS - content";

    }
    compressive_force_description() {
        return "F - force\np - pressure\nS - content";

    }
    hydraulic_press_description() {
        return "F1, F2 - force\nS1, S2 - content\n";

    }
    hydraulic_pressure_description() {
        return "p - pressure\nρ - density\ng - gravitational acceleration\nh - height";

    }
    hydrostatic_strength_description() {
        return "F - force\nρ - density\ng - gravitational acceleration\nh - height\nS - content";

    }
    hydrostatic_buoyancy_description() {
        return "B - buoyancy\nρ - density\ng - gravitational acceleration\nV - volume";

    }
    buoyancy() {
        return "B";

    }
    liquid_velocity_description() {
        return "v - velocity\nh - height\ng - gravitational acceleration";

    }
    resistance_force_description() {
        return "F - force\nρ - density\nS - content\nv - velocity";

        // Molecular physics and thermodynamics 


    }
    molecular_physics() {
        return "Molecular physics";

    }
    thermodynamics() {
        return "Thermodynamics";


    }
    heat_capacity() {
        return "Heat capacity";

    }
    specific_heat_capacity() {
        return "Specific heat capacity";

    }
    constant_volume() {
        return "- constant volume";

    }
    constant_pressure() {
        return "- constant pressure";

    }
    heat_capacity_ratio() {
        return "Heat capacity ratio";

    }
    isobaric_process() {
        return "Isobaric process - work";

    }
    cyclic_process() {
        return "Cyclic process - efficiency";

    }
    heat_engine() {
        return "Heat engine - efficiency";

    }
    hookes_law() {
        return "Hooke's law";

    }
    thermal_expansion_in_solids() {
        return "Thermal expansion in solids";

    }
    thermal_expansion_in_solidslinear_expansion() {
        return "Linear expansion";

    }
    thermal_expansion_in_solidsvolume_expansion() {
        return "Volume expansion";

    }
    linear_expansion() {
        return "- linear expansion";

    }
    volume_expansion() {
        return "- volume expansion";

    }
    thermal_expansion_volume() {
        return "Thermal expansion in liquid - volume";

    }
    surface_tension() {
        return "Surface tension of liquid";

    }
    capillary_pressure() {
        return "Capillary pressure";

    }
    specific_latent_heat() {
        return "Specific latent heat";

    }
    humidity() {
        return "Humidity";

    }
    humidityabsolute() {
        return "Absolute humidity";

    }
    humidityrelative() {
        return "Relative humidity";


    }
    absolute() {
        return "- absolute";

    }
    relative() {
        return "- relative";

        // description of Molecular physics and thermodynamics


    }
    heat_capacity_description() {
        return "C - capacity\nQ - heat\nΔT - temperature range";

    }
    specific_heat_capacity_description() {
        return "c - specific capacity\nQ - heat\nΔT - temperature range\nm - mass";

    }
    constant_volume_description() {
        return "c - specific capacity\nQ - heat\nΔT - temperature range\nm - mass";

    }
    constant_pressure_description() {
        return "c - specific capacity\nQ - heat\nΔT - temperature range\nm - mass";

    }
    heat_capacity_ratio_description() {
        return "γ - poisson constant\ncp - specific capacity (constant pressure)\ncv - constant capacity (constant volume)";

    }
    isobaric_process_description() {
        return "W´ - work\np - pressure\nΔV - volume range";

    }
    cyclic_process_description1() {
        return "η - efficiency\nW´ - isobaric work\nQ - heat";

    }
    cyclic_process_description2() {
        return "η - efficiency\nQ2, Q1 - heat";

    }
    cyclic_process_description3() {
        return "η - efficiency\nT2 -  absolute temperature of the hot source\nT1 - absolute temperature of the cold sink";

    }
    heat_engine_description() {
        return "η - efficiency\nT2 -  absolute temperature of the hot source\nT1 - absolute temperature of the cold sink";

    }
    hookes_law_description() {
        return "σ - mechanical stress\nE - Young´s modulus\nε - extensional strain";

    }
    linear_expansion_description() {
        return "l - length\nl0 - start length\nα - coefficient of thermal expansion\nΔt - temperature range";

    }
    volume_expansion_description() {
        return "V - volume\nV1 - start volume\nß - coefficient of thermal expansion\nΔt - temperature range";

    }
    thermal_expansion_volume_description() {
        return "V - volume\nV1 - start volume\nß - coefficient of thermal expansion\nΔt - temperature range";

    }
    surface_tension_description() {
        return "σ - surface tension\nF - force\nl - length";

    }
    capillary_pressure_description() {
        return "p - capillary pressure\nσ - surface tension\nr - radius";

    }
    specific_latent_heat_description() {
        return "l - specific latent heat\nL - latent heat\nm - mass";

    }
    relative_description() {
        return "Φ - absolute humidity\nm - mass\nV - volume";

    }
    absolute_description() {
        return "φ - relative humidity\nΦ - relative humidity\nΦm - relative humidity saturated with water vapor";

        // Mechanical oscillations and waves and acoustics 


    }
    mechanical_wave() {
        return "Mechanical wave";

    }
    acoustics() {
        return "Acoustics";

    }
    oscillation() {
        return "Oscillation";


    }
    oscillation_angular_frequency() {
        return "Oscillation - Angular frequency";

    }
    oscillator_angular_frequency() {
        return "Oscillator - Angular frequency";

    }
    oscillator_frequency() {
        return "Oscillator - Frequency";

    }
    mass_of_a_simple_pendulum() {
        return "Period of simple pendulum";

    }
    phase_velocity() {
        return "Phase velocity";

    }
    frequency_of_vibration() {
        return "Frequency of vibration";

    }
    snells_law() {
        return "Snell's law";

    }
    sound_intensity() {
        return "Sound intensity";

    }
    oscillator_force() {
        return "Oscillator - Force";

        // description of Mechanical oscillations and waves and acoustics 

    }
    oscillation_angular_frequency_description1() {
        return "ω - angular frequency\nT - time period";

    }
    oscillation_angular_frequency_description2() {
        return "ω - angular frequency\nf - frequency";

    }
    oscillator_angular_frequency_description() {
        return "ω - angular frequency\nk - positive constant\nm - mass";

    }
    oscillator_frequency_description() {
        return "f - frequency\nk - positive constant\nm - mass";

    }
    mass_of_a_simple_pendulum_description() {
        return "T - time period\nl - length\ng - gravitational acceleration";

    }
    phase_velocity_description1() {
        return "λ - wavelength\nv - velocity\nf - frequency";

    }
    phase_velocity_description2() {
        return "λ - wavelength\nv - velocity\nT - time period";

    }
    frequency_of_vibration_description() {
        return "f - frequency\nv - velocity\nk - positive constant\nl - length";

    }
    snells_law_description() {
        return "α, ß - angle\nv1, v2 - velocity";

    }
    sound_intensity_description() {
        return "I - sound intensity\nP - performance\nS - content";

    }
    oscillator_force_description() {
        return "F - force\nk - positive constant\n";

        // Optics 


    }
    refractive_index() {
        return "Refractive index";

    }
    law_of_reflection() {
        return "Law of reflection";

    }
    critical_angle() {
        return "Critical angle";

    }
    the_mirror_equation_concave_mirrors() {
        return "The mirror equation - concave mirrors";

    }
    the_mirror_equation_lens() {
        return "The mirror equation - lens";

    }
    focal_length_of_mirror() {
        return "Focal length of mirror";
        //
    }
    focal_length_of_lens() {
        return "Focal length of lens";

    }
    optical_power() {
        return "Optical power";

    }
    linear_magnification() {
        return "Linear magnification";

    }
    angular_magnification() {
        return "Angular magnification";

    }
    magnification_of_mirror_lens1() {
        return "Magnification of mirror, lens 1";

    }
    magnification_of_mirror_lens2() {
        return "Magnification of mirror, lens 2";
        //
    }
    magnification_of_mirror_lens3() {
        return "Magnification of mirror, lens 3";

    }
    angular_magnification_of_magnifier() {
        return "Angular magnification of magnifier";

    }
    angular_magnification_of_microscope() {
        return "Angular magnification of microscope";

    }
    angular_magnification_of_telescope() {
        return "Angular magnification of telescope";

    }
    wavelength_in_vacuum() {
        return "Wavelength in vacuum";

    }
    optical_path() {
        return "Optical path";

    }
    luminosity() {
        return "Luminosity";

    }
    illuminance1() {
        return "Illuminance 1";

    }
    illuminance2() {
        return "Illuminance 2";

    }
    wiens_displacement_law() {
        return "Wien's displacement law";

    }
    Stefan_Boltzmann_law() {
        return "Stefan–Boltzmann law";

        // description of optics 


    }
    refractive_index_description() {
        return "n - refractive index\nc - speed of light in vacuum\nv - phase velocity";

    }
    law_of_reflection_description() {
        return "α, α' - angle of reflection";

    }
    critical_angle_description() {
        return "α - critical angle\nn2, n1 - refractive index of environments";

    }
    the_mirror_equation_concave_mirrors_description() {
        return "a - distance of the object from the lens\na' - distance of the image from the lens\nr - radius of curvature";

    }
    the_mirror_equation_lens_description() {
        return "a - distance of the object from the lens\na' - distance of the image from the lens\nf - focal length";

    }
    focal_length_of_mirror_description() {
        return "f - focal length\nr - radius of curvature";

    }
    focal_length_of_lens_description() {
        return "f - focal length\nr1, r2 - radius of curvature\nn1, n2 - refractive index of environments";

    }
    optical_power_description() {
        return "P - optical power\nf - focal length";

    }
    optical_power_() {
        return "P";

    }
    linear_magnification_description() {
        return "Z - linear magnification\ny - object size\ny' - image size";

    }
    angular_magnification_description() {
        return "γ - angular magnification\nτ - object size\nτ' - image size";

    }
    magnification_of_mirror_lens1_description() {
        return "Z - magnification\na - distance of the object from the lens\na' - distance of the image from the lens";

    }
    magnification_of_mirror_lens2_description() {
        return "Z - magnification\na' - distance of the image from the lens\nf - focal length";

    }
    magnification_of_mirror_lens3_description() {
        return "Z - magnification\na - distance of the object from the lens\nf - focal length";

    }
    angular_magnification_of_magnifier_description() {
        return "γ - angular magnification\nd - conventional visual distance\na - distance of the object from the lens";

    }
    angular_magnification_of_microscope_description() {
        return "γ - angular magnification\nf1 - focal lengths\nf2 - subject focal length\nΔ - optical microscope interval\nd - conventional visual distance";

    }
    angular_magnification_of_telescope_description() {
        return "γ - angular magnification\nf1 - focal lengths\nf2 - subject focal length";

    }
    wavelength_in_vacuum_description() {
        return "λ0 - wavelength in vacuum\nλ - wavelength in environment\nn - refractive index";

    }
    optical_path_description() {
        return "l - optical path\nn - refractive index\ns - coefficient of runway length";

    }
    luminosity_description() {
        return "I - luminosity\nΔΩ - solid angle\nΔΦ - luminous flux";

    }
    illuminance1_description() {
        return "E - illuminance\nΔΦ - luminous flux\nΔS - area";

    }
    illuminance2_description() {
        return "E - illuminance\nI - current\nr - distance from the source\nα - angle of incidence beam";

    }
    wiens_displacement_law_description() {
        return "λ - wavelength of maximum\nT - absolute temperature\nb - constant of proportionality";

    }
    Stefan_Boltzmann_law_description() {
        return "I - radiation intensity\nT - thermodynamic temperature\nσ - Stefan–Boltzmann constant";

        // Electricity and magnetism 


    }
    electricity() {
        return "Electricity";

    }
    magnetism() {
        return "Magnetism";

    }
    electromagnetism() {
        return "Electromagnetism";


    }
    coulombs_law() {
        return "Coulomb's law";

    }
    electric_intensity1() {
        return "Electric intensity 1";

    }
    electric_intensity2() {
        return "Electric intensity 2";

    }
    charge_density() {
        return "Charge density";

    }
    electric_potential1() {
        return "Electric potential 1";

    }
    electric_potential2() {
        return "Electric potential 2";

    }
    electrical_work() {
        return "Electrical work";

    }
    capacitance_of_conductor() {
        return "Capacitance of conductor";

    }
    capacitance_of_capacitor() {
        return "Capacitance of capacitor";

    }
    work_of_capacitor() {
        return "Work of capacitor";

    }
    electric_current() {
        return "Electric current";

    }
    ohms_law() {
        return "Ohm's law";

    }
    ohms_law_for_closed_circuit() {
        return "Ohm's law for closed circuit";

    }
    conductance() {
        return "Electrical conductance";

    }
    electrical_resistivity() {
        return "Electrical resistivity";

    }
    resistance_wire() {
        return "Resistance wire";

    }
    terminal_voltage() {
        return "Terminal voltage";

    }
    electrical_work_with_constant_current() {
        return "Electrical work with constant current";

    }
    electric_power() {
        return "Electric power";

    }
    faradays_law() {
        return "Faraday's law";

    }
    ampères_force_law() {
        return "Ampère's force law";

    }
    ampères_force_law_on_a_charged_particle() {
        return "Ampère's force law on a charged particle";

    }
    force_between_two_straight_parallel_conductors() {
        return "Force between two straight parallel conductors";


    }
    magnetic_induction() {
        return "Magnetic induction";

    }
    magnetic_induction_of_linear_wire() {
        return "Magnetic induction of linear wire";

    }
    magnetic_induction_of_circular_loop() {
        return "Magnetic induction of circular loop";

    }
    magnetic_induction_of_solenoid() {
        return "Magnetic induction of solenoid";


    }
    _of_linear_wire() {
        return "- of linear wire";

    }
    _of_circular_loop() {
        return "- of circular loop";

    }
    _of_solenoid() {
        return "- of solenoid";


    }
    magnetic_field_intensity() {
        return "Magnetic field intensity";

    }
    magnetic_flux() {
        return "Magnetic flux";

    }
    faradays_law_of_induction() {
        return "Faraday's law of induction";

    }
    inductance() {
        return "Inductance";

    }
    electromotive_force_in_solenoid() {
        return "Electromotive force in solenoid";

    }
    magnetic_energy_in_inductor() {
        return "Magnetic energy in inductor";

    }
    effective_value_of_voltage() {
        return "Effective value of voltage";

    }
    effective_value_of_current() {
        return "Effective value of current";

    }
    electrical_impedance() {
        return "Electrical impedance";

    }
    phase_shift() {
        return "Phase shift";

    }
    circuit_inductance() {
        return "Circuit inductance";

    }
    resonance_effect() {
        return "Resonance effect";


    }
    alternating_current_power() {
        return "Alternating current power";

    }
    alternating_current_power_with_resistance() {
        return "Alternating current power with resistance";

    }
    alternating_current_power_with_impedance() {
        return "Alternating current power with impedance";

    }
    _with_resistance() {
        return "-  with resistance";

    }
    _with_impedance() {
        return "- with impedance";


    }
    the_speed_of_propagation_of_electromagnetic_waves() {
        return "Velocity of propagation of electromagnetic waves";

    }
    the_speed_of_propagation_of_electromagnetic_waves_in_vacuum() {
        return "Velocity of propagation of electromagnetic waves in vacuum";

    }
    the_speed_of_propagation_of_electromagnetic_waves_in_environment() {
        return "Velocity of propagation of electromagnetic waves in environment";

    }
    _in_vacuum() {
        return "- in vacuum";

    }
    _in_environment() {
        return "- in environment";

        // description of Electricity and magnetism


    }
    coulombs_law_description() {
        return "F - force of interaction between the charges\nQ1, Q2 - signed magnitudes of the charges\nr - distance between the charges\nε - permittivity";

    }
    electric_intensity1_description() {
        return "E - electric intensity\nF - force of interaction between the charges\nQ - electrical charge";

    }
    electric_intensity2_description() {
        return "E - electric intensity\nQ - electrical charge\nε - permittivity\nr - distance";

    }
    charge_density_description() {
        return "σ - charge density\nQ - electrical charge\nS - area";

    }
    electric_potential1_description() {
        return "φ - electric potential\nE - electric intensity\nQ - electrical charge";

    }
    electric_potential2_description() {
        return "φ - electric potential\nW - electrical work\nQ - electrical charge";

    }
    electrical_work_description() {
        return "W - electrical work\nQ - electrical charge\nU - voltage";

    }
    capacitance_of_conductor_description() {
        return "C - capacitance of conductor\nQ - electrical charge\nU - voltage";

    }
    capacitance_of_capacitor_description() {
        return "C - capacitance of capacitor\nε - permittivity\nS - area\nd - thickness between two conducting plates";

    }
    work_of_capacitor_description() {
        return "W - work of capacitor\nC - capacitance of capacitor\nU - voltage";

    }
    electric_current_description() {
        return "I - electric current\nQ - electric charge\nt - time";

    }
    ohms_law_description() {
        return "I - electric current\nU - voltage\nR - resistance";

    }
    ohms_law_for_closed_circuit_description() {
        return "I - electric current\nU - voltage\nR, R1 - resistance";

    }
    conductance_description() {
        return "G - electrical conductance\nR - resistance";

    }
    electrical_resistivity_description() {
        return "ρ - electrical resistivity\nR - resistance\nS - area\nl - length of conductor";

    }
    resistance_wire_description() {
        return "R - resistance of conductor\nρ - electrical resistivity\nl - length of conductor\nd - diameter";

    }
    terminal_voltage_description() {
        return "U - terminal voltage\nUe - voltage\nR - resistance\nI - current";

    }
    electrical_work_with_constant_current_description() {
        return "W - electrical work\nU - voltage\nI - current\nt - time";

    }
    electric_power_description() {
        return "P - electric power\nU - voltage\nI - current";

    }
    faradays_law_description() {
        return "m - mass of the substance liberated at an electrode\nA - electrochemical equivalent\nQ - electrical charge";

    }
    ampères_force_law_description() {
        return "F - force\nB - magnetic induction\nI - current\nl - length of conductor\nα - angle";

    }
    ampères_force_law_on_a_charged_particle_description() {
        return "F - force\nQ - electrical charge\nv -  velocity\nB - magnetic induction\nα - angle";

    }
    force_between_two_straight_parallel_conductors_description() {
        return "F - force\nμ - permeability\nI1, I2 - current\nl - length\nd - distance between conductor";


    }
    _of_linear_wire_description() {
        return "B - magnetic induction\nμ - permeability\nI - current\nd - distance";

    }
    _of_circular_loop_description() {
        return "B - magnetic induction\nμ - permeability\nr - radius of circular loop";

    }
    _of_solenoid_description() {
        return "B - magnetic induction\nμ - permeability\nN - number of turns\nI - current\nl - length of solenoid";


    }
    magnetic_field_intensity_description() {
        return "H - magnetic field intensity\nB - magnetic induction\nμ - permeability";

    }
    magnetic_flux_description() {
        return "ϕ - magnetic flux\nB - magnetic induction\nS - area\nα - angle";

    }
    faradays_law_of_induction_description() {
        return "U - electromagnetic voltage\nϕ - magnetic flux\nt - time";

    }
    inductance_description() {
        return "L - inductance\nϕ - magnetic flux\nI - current";

    }
    electromotive_force_in_solenoid_description() {
        return "U - electromotive force\nL - inductance\nI - current\nt - time";

    }
    magnetic_energy_in_inductor_description() {
        return "E - magnetic energy in inductor\nL - inductance\nI - current";

    }
    effective_value_of_voltage_description() {
        return "U - effective value of voltage\nUm - maximum value of voltage";

    }
    effective_value_of_current_description() {
        return "I - effective value of current\nIm - maximum value of current";

    }
    electrical_impedance_description() {
        return "Z - electrical impedance\nR - resistance\nX - inductance";

    }
    phase_shift_description() {
        return "φ - phase\nX - inductance\nR - resistance";

    }
    circuit_inductance_description() {
        return "X - inductance\nω - angular velocity\nL - inductance";

    }
    resonance_effect_description() {
        return "ω - resonance frequency\nL - inductance\nC - capacitance";


    }
    _with_resistance_description() {
        return "P - electric power\nU - voltage\nI - current";

    }
    _with_impedance_description() {
        return "P - electric power\nU - voltage\nI - current\nφ - phase";


    }
    _in_vacuum_description() {
        return "c - velocity in vacuum\nε - permittivity of vacuum\nμ - permeability of vacuum";

    }
    _in_environment_description() {
        return "v - velocity in environment\nc - light speed\nε - permittivity of environment\nμ - permeability of environment";


        // The Special theory of Relativity 


    }
    time_dilation() {
        return "Time dilation";

    }
    length_contraction() {
        return "Length contraction";

    }
    composition_law_for_velocities() {
        return "Composition law for velocities";

    }
    relativistic_mass() {
        return "Relativistic mass";

    }
    mass_energy_equivalence() {
        return "Mass–energy equivalence";

    }
    increment_of_energy() {
        return "Increment of energy";

    }
    resting_energy() {
        return "Resting energy";

        // description of the special theory of relativity 

    }
    time_dilation_description() {
        return "t - time\nt0 - start time\nv - velocity\nc - speed of light\n";

    }
    length_contraction_description() {
        return "l - length\nl0 - start length\nv - velocity\nc - speed of light\n";

    }
    composition_law_for_velocities_description() {
        return "u, u´, v - velocity\nc - speed of light";

    }
    relativistic_mass_description() {
        return "m - mass\nm0 - start mass\nv - velocity\nc - speed of light";

    }
    mass_energy_equivalence_description1() {
        return "E - energy\nE0 - increment energy\nEk - kinetic energy";

    }
    mass_energy_equivalence_description2() {
        return "E - energy\nm - mass\nc - speed of light";

    }
    mass_energy_equivalence_description3() {
        return "E - energy\nm - mass\nc - speed of light v - velocity";

    }
    increment_of_energy_description() {
        return "E0 - increment energy\nm - mass\nc - speed of light";

    }
    resting_energy_description() {
        return "ΔE - energy\nm - mass\nc - speed of light";

        // Quantum Physics 


    }
    photon_energy() {
        return "Photon energy";

    }
    photon_momentum() {
        return "Photon momentum";

    }
    photon_mass() {
        return "Photon mass";

    }
    einstein_equation() {
        return "Einstein's equation";

    }
    De_Broglie_wavelength_relationship() {
        return "De Broglie wavelength relationship";

    }
    binding_energy() {
        return "Binding energy";

    }
    nuclear_binding_energy() {
        return "Nuclear binding energy";

    }
    expansion_velocity_of_galaxies() {
        return "Expansion velocity of galaxies";

    }
    cutoff_wavelength_of_X_rays() {
        return "Cutoff wavelength of X-rays";

        // description of Quantum physics

    }
    photon_energy_description() {
        return "E - energy of photon\nh - Planck's constant (h = 6, 626×10 ^−34 J·s)\nf - frequency";

    }
    photon_momentum_description() {
        return "p - momentum\nh - Planck's constant (h = 6, 626×10 ^−34 J·s)\nc - speed of light\nf - frequency";

    }
    photon_weight_description() {
        return "m - mass\nh - Planck's constant (h = 6, 626×10 ^−34 J·s)\nλ - wavelength\nc - speed of light";

    }
    einstein_equation_description() {
        return "h - Planck's constant (h = 6, 626×10 ^−34 J·s)\nf - frequency\nWv - input work\nm - mass\nv - velocity";

    }
    De_Broglie_wavelength_relationship_description() {
        return "h - Planck's constant (h = 6, 626×10 ^−34 J·s)\nm - mass\nλ - wavelength\nv - velocity";

    }
    binding_energy_description() {
        return "Wb - binding energy\nB -  mass change\nc - speed of light";

    }
    nuclear_binding_energy_description() {
        return "\n";

    }
    expansion_velocity_of_galaxies_description() {
        return "v - velocity\nH -  Hubble parameter\nr - distance galaxy";

    }
    cutoff_wavelength_of_X_rays_description() {
        return "λ - wavelength\nh - Planck's constant (h = 6, 626×10 ^−34 J·s)\nc - peed of light\ne - electric charge of electron\nU - voltage";
    }
}

export default new Strings();
