import Strings from "./strings.helper";

class MolecularPhysicsAndThermodynamic {
    getFormulas() {

        const titleString = "Molecular physics and thermodynamics";
        var namesList = [];
        var linksList = [];
        var itemsList = [];

        linksList.push("/physics/molecular-physics-thermodynamic/heat-capacity");
        namesList.push("Heat capacity");

        linksList.push("/physics/molecular-physics-thermodynamic/specific-heat-capacity");
        namesList.push("Specific heat capacity");

        linksList.push("/physics/molecular-physics-thermodynamic/heat-capacity-ratio");
        namesList.push("Heat capacity ratio");

        linksList.push("/physics/molecular-physics-thermodynamic/isobaric-process-work");
        namesList.push("Isobaric process - work");

        linksList.push("/physics/molecular-physics-thermodynamic/cycling-process-efficiency");
        namesList.push("Cyclic process - efficiency");

        linksList.push("/physics/molecular-physics-thermodynamic/cycling-process-efficiency2");
        namesList.push("Cyclic process - efficiency 2");

        linksList.push("/physics/molecular-physics-thermodynamic/cycling-process-efficiency3");
        namesList.push("Cyclic process - efficiency 3");

        linksList.push("/physics/molecular-physics-thermodynamic/heat-engine");
        namesList.push("Heat engine - efficiency");

        linksList.push("/physics/molecular-physics-thermodynamic/hooke-law");
        namesList.push("Hooke's law");

        linksList.push("/physics/molecular-physics-thermodynamic/thermal-expansion-in-solids-volume-expansion");
        namesList.push("Thermal expansion in liquid - volume");

        linksList.push("/physics/molecular-physics-thermodynamic/surface-tension-of-liquid");
        namesList.push("Surface tension of liquid");

        linksList.push("/physics/molecular-physics-thermodynamic/capillary-pressure");
        namesList.push("Capillary pressure");

        linksList.push("/physics/molecular-physics-thermodynamic/specific-latent-heat");
        namesList.push("Specific latent heat");

        linksList.push("/physics/molecular-physics-thermodynamic/humidityrelative");
        namesList.push("Absolute humidity");

        linksList.push("/physics/molecular-physics-thermodynamic/humidityabsolute");
        namesList.push("Relative humidity");

        linksList.push("/physics/molecular-physics-thermodynamic/thermal-expansion-in-solidslinear-expansion");
        namesList.push("Thermal expansion in solids - linear expansion");

        linksList.push("/physics/molecular-physics-thermodynamic/thermal-expansion-in-solidsvolume-expansion");
        namesList.push("Thermal expansion in solids - volume expansion");

        linksList.map((linkT, index) => {
            var item = {
                link: linkT,
                name: namesList[index]
            }

            itemsList.push(item);
        });

        return {
            title: titleString,
            items: itemsList
        }
    }

    getFomulaParams(formulaName) {
        switch (formulaName) {
            case "heat-capacity":
               return this.heat_capacity();
            case "specific-heat-capacity":
               return this.specific_heat_capacity();
            case "heat-capacity-ratio":
               return this.heat_capacity_ratio();
            case "isobaric-process-work":
               return this.isobaric_process_work();
            case "cycling-process-efficiency":
               return this.cycling_process_efficiency();
            case "cycling-process-efficiency2":
               return this.cycling_process_efficiency2();
            case "cycling-process-efficiency3":
               return this.cycling_process_efficiency3();
            case "heat-engine":
               return this.heat_engine();
            case "hooke-law":
               return this.hooke_law();
            case "thermal-expansion-in-solids-volume-expansion":
               return this.thermal_expansion_in_solids_volume_expansion();
            case "surface-tension-of-liquid":
               return this.surface_tension_of_liquid();
            case "capillary-pressure":
               return this.capillary_pressure();
            case "specific-latent-heat":
               return this.specific_latent_heat();
            case "humidityrelative":
               return this.humidityrelative();
            case "humidityabsolute":
               return this.humidityabsolute();
            case "thermal-expansion-in-solidslinear-expansion":
               return this.thermal_expansion_in_solidslinear_expansion();
            case "thermal-expansion-in-solidsvolume-expansion":
               return this.thermal_expansion_in_solidsvolume_expansion();
        }
    }

    heat_capacity() {
        return {
            calculatorType: "e1Ee2De3",
            imagePath: "heat_capacity",
            title: Strings.heat_capacity(),
            description: Strings.heat_capacity_description(),
            element1: "C",
            baseUnit1: "J/K",
            element2: "Q",
            baseUnit2: "J",
            element3: "ΔT",
            baseUnit3: "K",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    specific_heat_capacity() {
        return {
            calculatorType: "e1Ee2De3e4",
            imagePath: "specific_heat_capacity",
            title: Strings.specific_heat_capacity(),
            description: Strings.specific_heat_capacity_description(),
            element1: "c",
            baseUnit1: "Jkg^-1K^-1",
            element2: "Q",
            baseUnit2: "J",
            element3: "m",
            baseUnit3: "kg",
            element4: "ΔT",
            baseUnit4: "K",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    heat_capacity_ratio() {
        return {
            calculatorType: "e1Ee2De3",
            imagePath: "heat_capacity_ratio",
            title: Strings.heat_capacity_ratio(),
            description: Strings.heat_capacity_ratio_description(),
            element1: "γ",
            baseUnit1: " ",
            element2: "cp",
            baseUnit2: "Jkg^-1K^-1",
            element3: "cv",
            baseUnit3: "Jkg^-1K^-1",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    isobaric_process_work() {
        return {
            calculatorType: "e1Ee2e3",
            imagePath: "isobaric_process_work",
            title: Strings.isobaric_process(),
            description: Strings.isobaric_process_description(),
            element1: "W´",
            baseUnit1: "J",
            element2: "p",
            baseUnit2: "Pa",
            element3: "ΔV",
            baseUnit3: "l",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    cycling_process_efficiency() {
        return {
            calculatorType: "e1Ee2De3",
            imagePath: "cycling_process_efficiency",
            title: Strings.cyclic_process(),
            description: Strings.cyclic_process_description1(),
            element1: "η",
            baseUnit1: " ",
            element2: "W´",
            baseUnit2: "J",
            element3: "Q",
            baseUnit3: "J",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    cycling_process_efficiency2() {
        return {
            calculatorType: "e1E1Se2de3",
            imagePath: "cycling_process_efficiency2",
            title: Strings.cyclic_process(),
            description: Strings.cyclic_process_description2(),
            element1: "η",
            baseUnit1: " ",
            element2: "Q2",
            baseUnit2: "J",
            element3: "Q1",
            baseUnit3: "J",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    cycling_process_efficiency3() {
        return {
            calculatorType: "e1E1Se2de3",
            imagePath: "cycling_process_efficiency3",
            title: Strings.cyclic_process(),
            description: Strings.cyclic_process_description3(),
            element1: "η",
            baseUnit1: " ",
            element2: "T2",
            baseUnit2: "J",
            element3: "T",
            baseUnit3: "J",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    heat_engine() {
        return {
            calculatorType: "e1E1Se2de3",
            imagePath: "heat_engine",
            title: Strings.heat_engine(),
            description: Strings.cyclic_process_description3(),
            element1: "η",
            baseUnit1: " ",
            element2: "T2",
            baseUnit2: "J",
            element3: "T",
            baseUnit3: "J",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    hooke_law() {
        return {
            calculatorType: "e1Ee2e3",
            imagePath: "hooke_law",
            title: Strings.hookes_law(),
            description: Strings.hookes_law_description(),
            element1: "σ",
            baseUnit1: "Pa",
            element2: "ε",
            baseUnit2: " ",
            element3: "E",
            baseUnit3: "Pa",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    thermal_expansion_in_solids_volume_expansion() {
        return {
            calculatorType: "e1Ee2M1ate3",
            imagePath: "thermal_expansion_in_solids_volume_expansion",
            title: Strings.thermal_expansion_volume(),
            description: Strings.thermal_expansion_volume_description(),
            element1: "V",
            baseUnit1: "l",
            element2: "V0",
            baseUnit2: "l",
            element3: "ß",
            baseUnit3: "K^-1",
            element4: "ΔT",
            baseUnit4: "K",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    surface_tension_of_liquid() {
        return {
            calculatorType: "e1Ee2De3",
            imagePath: "surface_tension_of_liquid",
            title: Strings.surface_tension(),
            description: Strings.surface_tension_description(),
            element1: "σ",
            baseUnit1: "N/m",
            element2: "F",
            baseUnit2: "N",
            element3: "l",
            baseUnit3: "m",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    capillary_pressure() {
        return {
            calculatorType: "e1E2e2De3",
            imagePath: "capillary_pressure",
            title: Strings.capillary_pressure(),
            description: Strings.resting_energy_description(),
            element1: "p",
            baseUnit1: "Pa",
            element2: "σ",
            baseUnit2: "N/m",
            element3: "r",
            baseUnit3: "m",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null

        }
    }

    specific_latent_heat() {
        return {
            calculatorType: "e1Ee2De3",
            imagePath: "specific_latent_heat",
            title: Strings.specific_latent_heat(),
            description: Strings.specific_latent_heat_description(),
            element1: "l",
            baseUnit1: "J/kg",
            element2: "L",
            baseUnit2: "J",
            element3: "m",
            baseUnit3: "kg",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    humidityrelative() {
        return {
            calculatorType: "e1Ee2De3",
            title: Strings.humidityrelative(),
            description: Strings.relative_description(),
            element1: "Φ",
            baseUnit1: "g/m^3",
            element2: "m",
            baseUnit2: "kg",
            element3: "V",
            baseUnit3: "l",
            imagePath: "humidity_relative",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    humidityabsolute() {
        return {
            calculatorType: "e1Ee2De3",
            title: Strings.humidityabsolute(),
            description: Strings.absolute_description(),
            element1: "φ",
            baseUnit1: " ",
            element2: "Φ",
            baseUnit2: "g/m^3",
            element3: "Φm",
            baseUnit3: "g/m^3",
            imagePath: "humidity_absolute",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    thermal_expansion_in_solidslinear_expansion() {
        return {
            calculatorType: "e1Ee2M1ate3",
            title: Strings.thermal_expansion_in_solidslinear_expansion(),
            description: Strings.linear_expansion_description(),
            element1: "l",
            baseUnit1: "m",
            element2: "l0",
            baseUnit2: "m",
            element3: "α",
            baseUnit3: "K^-1",
            element4: "ΔT",
            baseUnit4: "K",
            imagePath: "thermal_expansion_in_solids_linear_expansion",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    thermal_expansion_in_solidsvolume_expansion() {
        return {
            calculatorType: "e1Ee2M1ate3",
            title: Strings.thermal_expansion_in_solidsvolume_expansion(),
            description: Strings.volume_expansion_description(),
            element1: "V",
            baseUnit1: "l",
            element2: "V0",
            baseUnit2: "l",
            element3: "ß",
            baseUnit3: "K^-1",
            element4: "ΔT",
            baseUnit4: "K",
            imagePath: "thermal_expansion_in_solids_volume_expansion",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }
}

export default new MolecularPhysicsAndThermodynamic();
