import Strings from "./strings.helper";

class SpecialTheoryOfRelativity {
    getFormulas() {
        const titleString = "The special theory of relativity";
        var namesList = [];
        var linksList = [];
        var itemsList = [];

        linksList.push("/physics/special-theory-of-relativity/time-dilation");
        namesList.push("Time dilation");

        linksList.push("/physics/special-theory-of-relativity/length-contraction");
        namesList.push("Length contraction");

        linksList.push("/physics/special-theory-of-relativity/composition-law");
        namesList.push("Composition law for velocities");

        linksList.push("/physics/special-theory-of-relativity/relativistic-mass");
        namesList.push("Relativistic mass");

        linksList.push("/physics/special-theory-of-relativity/mass-energy-");
        namesList.push("Mass–energy equivalence");

        linksList.push("/physics/special-theory-of-relativity/mass-energy-2");
        namesList.push("Mass–energy equivalence 2");

        linksList.push("/physics/special-theory-of-relativity/mass-energy-3");
        namesList.push("Mass–energy equivalence 3");

        linksList.push("/physics/special-theory-of-relativity/increment-of-energy");
        namesList.push("Increment of energy");

        linksList.map((linkT, index) => {
            var item = {
                link: linkT,
                name: namesList[index]
            }

            itemsList.push(item);
        });

        return {
            title: titleString,
            items: itemsList
        }
    }

    getFormulaParams(formulaName) {
        switch (formulaName) {
            case "time-dilation":
               return this.time_dilation();
            case "length-contraction":
               return this.length_contraction();
            case "composition-law":
               return this.composition_law();
            case "relativistic-mass":
               return this.relativistic_mass();
            case "mass-energy-":
               return this.mass_energy__();
            case "mass-energy-2":
               return this.mass_energy__2();
            case "mass-energy-3":
               return this.mass_energy__3();
            case "increment-of-energy":
               return this.increment_of_energy();
        }
    }

    time_dilation() {
        return {
            calculatorType: "e1Ee2DSQRT1se3de4",
            imagePath: "time_dilation",
            title: Strings.time_dilation(),
            description: Strings.time_dilation_description(),
            element1: "Δt",
            baseUnit1: "s",
            element2: "Δt0",
            baseUnit2: "s",
            element3: "v",
            baseUnit3: "m/s",
            element4: "c",
            baseUnit4: "m/s",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    length_contraction() {
        return {
            calculatorType: "e1Ee2SQRT1se3de4",
            imagePath: "length_contraction",
            title: Strings.length_contraction(),
            description: Strings.length_contraction_description(),
            element1: "l",
            baseUnit1: "m",
            element2: "l0",
            baseUnit2: "m",
            element3: "v",
            baseUnit3: "m/s",
            element4: "c",
            baseUnit4: "m/s",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    composition_law() {
        return {
            calculatorType: "e1Ee2ae3D1se2e3de4p2",
            title: Strings.composition_law_for_velocities(),
            description: Strings.composition_law_for_velocities_description(),
            element1: "u",
            baseUnit1: "m/s",
            element2: "u´",
            baseUnit2: "m/s",
            element3: "v",
            baseUnit3: "m/s",
            element4: "c",
            baseUnit4: "m/s",
            imagePath: "composition_law",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    relativistic_mass() {
        return {
            calculatorType: "e1Ee2DSQRT1se3de4",
            title: Strings.relativistic_mass(),
            description: Strings.relativistic_mass_description(),
            element1: "m",
            baseUnit1: "kg",
            element2: "m0",
            baseUnit2: "kg",
            element3: "v",
            baseUnit3: "m/s",
            element4: "c",
            baseUnit4: "m/s",
            imagePath: "relativitstic_mass",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    mass_energy__() {
        return {
            calculatorType: "e1Ee2Ae3",
            title: Strings.mass_energy_equivalence(),
            description: Strings.mass_energy_equivalence_description1(),
            element1: "E",
            baseUnit1: "J",
            element2: "E0",
            baseUnit2: "J",
            element3: "Ek",
            baseUnit3: "J",
            imagePath: "mass_energy__",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    mass_energy__2() {
        return {
            calculatorType: "e1Ee2e3P2",
            title: Strings.mass_energy_equivalence(),
            description: Strings.mass_energy_equivalence_description2(),
            element1: "E",
            baseUnit1: "J",
            element2: "m",
            baseUnit2: "kg",
            element3: "c",
            baseUnit3: "m/s",
            imagePath: "mass_energy__2",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    mass_energy__3() {
        return {
            calculatorType: "e1Ee2e3P2DSQRT1se3P2de4P2",
            title: Strings.mass_energy_equivalence(),
            description: Strings.mass_energy_equivalence_description3(),
            element1: "E",
            baseUnit1: "J",
            element2: "m",
            baseUnit2: "kg",
            element3: "v",
            baseUnit3: "m/s",
            element4: "c",
            baseUnit4: "m/s",
            imagePath: "mass_energy__3",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    increment_of_energy() {
        return {
            calculatorType: "e1Ee2e3P2",
            title: Strings.increment_of_energy(),
            description: Strings.increment_of_energy_description(),
            element1: "E",
            baseUnit1: "J",
            element2: "m",
            baseUnit2: "kg",
            element3: "c",
            baseUnit3: "m/s",
            imagePath: "increment_of_energy",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }
}

export default new SpecialTheoryOfRelativity();
