import Strings from "./strings.helper";

class MechanicalOscillationsAndWavesAndAcoustics {
    getFormulas() {

        const titleString = "Mechanical oscillations and waves and acoustics";
        var namesList = [];
        var linksList = [];
        var itemsList = [];

        linksList.push("/physics/mechanical-vibration-waves-acoustic/oscillation-angular-frequency");
        namesList.push("Oscillation - Angular frequency");

        linksList.push("/physics/mechanical-vibration-waves-acoustic/oscillation-angular-frequency-2");
        namesList.push("Oscillation - Angular frequency 2");

        linksList.push("/physics/mechanical-vibration-waves-acoustic/oscilator-force");
        namesList.push("Oscillator - Force");

        linksList.push("/physics/mechanical-vibration-waves-acoustic/oscillator-angular-frequency");
        namesList.push("Oscillator - Angular frequency");

        linksList.push("/physics/mechanical-vibration-waves-acoustic/oscillator-frequency");
        namesList.push("Oscillator - Frequency");

        linksList.push("/physics/mechanical-vibration-waves-acoustic/period-of-simple-pendulum");
        namesList.push("Period of simple pendulum");

        linksList.push("/physics/mechanical-vibration-waves-acoustic/phase-velocity");
        namesList.push("Phase velocity");

        linksList.push("/physics/mechanical-vibration-waves-acoustic/phase-velocity-2");
        namesList.push("Phase velocity 2");

        linksList.push("/physics/mechanical-vibration-waves-acoustic/frequency-of-vibration");
        namesList.push("Frequency of vibration");

        linksList.push("/physics/mechanical-vibration-waves-acoustic/snells-law");
        namesList.push("Snell's law");

        linksList.push("/physics/mechanical-vibration-waves-acoustic/sound-intensity");
        namesList.push("Sound intensity");


        linksList.map((linkT, index) => {
            var item = {
                link: linkT,
                name: namesList[index]
            }

            itemsList.push(item);
        });

        return {
            title: titleString,
            items: itemsList
        }
    }

    getFormulaParams(formulaName) {
        switch (formulaName) {
            case "oscillation-angular-frequency":
               return this.oscillation_angular_frequency();
            case "oscillation-angular-frequency-2":
               return this.oscillation_angular_frequency();
            case "oscilator-force":
               return this.oscilator_force();
            case "oscillator-angular-frequency":
               return this.oscillator_angular_frequency();
            case "oscillator-frequency":
               return this.oscillator_frequency();
            case "period-of-simple-pendulum":
               return this.period_of_simple_pendulum();
            case "phase-velocity":
               return this.phase_velocity();
            case "phase-velocity-2":
               return this.phase_velocity_2();
            case "frequency-of-vibration":
               return this.frequency_of_vibration();
            case "snells-law":
               return this.snells_law();
            case "sound-intensity":
               return this.sound_intensity();
        }
    }

    oscillation_angular_frequency() {
        return {
            calculatorType: "e1E2PIDe2",
            imagePath: "oscillation_angular_frequency",
            title: Strings.oscillation_angular_frequency(),
            description: Strings.oscillation_angular_frequency_description1(),
            element1: "ω",
            baseUnit1: "s^-1",
            element2: "T",
            baseUnit2: "s",
            element3: null,
            baseUnit3: null,
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    oscillation_angular_frequency_2() {
        return {
            calculatorType: "e1E2PIe2",
            imagePath: "oscillation_angular_frequency_2",
            title: Strings.oscillation_angular_frequency(),
            description: Strings.oscillation_angular_frequency_description2(),
            element1: "ω",
            baseUnit1: "s^-1",
            element2: "f",
            baseUnit2: "Hz",
            element3: null,
            baseUnit3: null,
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    oscilator_force() {
        return {
            calculatorType: "e1Eminuse2e3",
            imagePath: "oscilator_force",
            title: Strings.oscillator_force(),
            description: Strings.oscillator_force_description(),
            element1: "F",
            baseUnit1: "N",
            element2: "k",
            baseUnit2: "N/m",
            element3: "y",
            baseUnit3: "m",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    oscillator_angular_frequency() {
        return {
            calculatorType: "e1ESQRTe2de3",
            imagePath: "oscillator_angular_frequency",
            title: Strings.oscillator_angular_frequency(),
            description: Strings.oscillator_angular_frequency_description(),
            element1: "ω",
            baseUnit1: "s^-1",
            element2: "k",
            baseUnit2: "N/M",
            element3: "m",
            baseUnit3: "kg",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    oscillator_frequency() {
        return {
            calculatorType: "e1E1D2PIMSQRTe2de3",
            imagePath: "oscillator_frequency",
            title: Strings.oscillator_frequency(),
            description: Strings.oscillator_frequency_description(),
            element1: "f",
            baseUnit1: "Hz",
            element2: "k",
            baseUnit2: "N/m",
            element3: "m",
            baseUnit3: "kg",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    period_of_simple_pendulum() {
        return {
            calculatorType: "e1E2PISQRTe2de3",
            imagePath: "period_of_simple_pendulum",
            title: Strings.mass_of_a_simple_pendulum(),
            description: Strings.mass_of_a_simple_pendulum_description(),
            element1: "T",
            baseUnit1: "s",
            element2: "l",
            baseUnit2: "m",
            element3: "g",
            baseUnit3: "m/s^2",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    phase_velocity() {
        return {
            calculatorType: "e1Ee2e3",
            imagePath: "/phase_velocity",
            title: Strings.phase_velocity(),
            description: Strings.phase_velocity_description1(),
            element1: "v",
            baseUnit1: "m/s",
            element2: "λ",
            baseUnit2: "m",
            element3: "f",
            baseUnit3: "Hz",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    phase_velocity_2() {
        return {
            calculatorType: "e1Ee2De3",
            imagePath: "/phase_velocity_2",
            title: Strings.phase_velocity(),
            description: Strings.phase_velocity_description2(),
            element1: "v",
            baseUnit1: "m/s",
            element2: "λ",
            baseUnit2: "m",
            element3: "T",
            baseUnit3: "s",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    frequency_of_vibration() {
        return {
            calculatorType: "e1Ee2e3De4",
            imagePath: "frequency_of_vibration",
            title: Strings.frequency_of_vibration(),
            description: Strings.frequency_of_vibration_description(),
            element1: "f",
            baseUnit1: "Hz",
            element2: "k",
            baseUnit2: " ",
            element3: "v",
            baseUnit3: "m/s",
            element4: "l",
            baseUnit4: "m",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    snells_law() {
        return {
            calculatorType: "sine1Dsine2Ee3De4",
            imagePath: "snells_law",
            title: Strings.snells_law(),
            description: Strings.snells_law_description(),
            element1: "α",
            baseUnit1: "°",
            element2: "ß",
            baseUnit2: "°",
            element3: "v1",
            baseUnit3: "m/s",
            element4: "v2",
            baseUnit4: "m/s",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    sound_intensity() {
        return {
            calculatorType: "e1Ee2De3",
            imagePath: "sound_intensity",
            title: Strings.sound_intensity(),
            description: Strings.sound_intensity_description(),
            element1: "I",
            baseUnit1: "W/m^2",
            element2: "P",
            baseUnit2: "W",
            element3: "S",
            baseUnit3: "m^2",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

}

export default new MechanicalOscillationsAndWavesAndAcoustics();
