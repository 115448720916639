import Strings from "./strings.helper"

class ElectricityAndMagnetismus {
    getFormulas() {
        const titleString = "Electricity and magnetism";
        var linksList = [];
        var namesList = [];
        var itemsList = [];

        linksList.push("/physics/electricity-and-magnetismus/coulomb-law");
        namesList.push("Coulomb's law");

        linksList.push("/physics/electricity-and-magnetismus/electric-intensity-1");
        namesList.push("Electric intensity 1");

        linksList.push("/physics/electricity-and-magnetismus/electric-intensity-2");
        namesList.push("Electric intensity 2");

        linksList.push("/physics/electricity-and-magnetismus/charge-density");
        namesList.push("Charge density");

        linksList.push("/physics/electricity-and-magnetismus/electric-potential-1");
        namesList.push("Electric potential 1");

        linksList.push("/physics/electricity-and-magnetismus/electric-potential-2");
        namesList.push("Electric potential 2");

        linksList.push("/physics/electricity-and-magnetismus/electrical-work");
        namesList.push("Electrical work");

        linksList.push("/physics/electricity-and-magnetismus/capacitance-of-conductor");
        namesList.push("Capacitance of conductor");

        linksList.push("/physics/electricity-and-magnetismus/capacitance-of-capacitor");
        namesList.push("Capacitance of capacitor");

        linksList.push("/physics/electricity-and-magnetismus/work-of-capacitor");
        namesList.push("Work of capacitor");

        linksList.push("/physics/electricity-and-magnetismus/electric-current");
        namesList.push("Electric current");

        linksList.push("/physics/electricity-and-magnetismus/ohms-law");
        namesList.push("Ohm's law");

        linksList.push("/physics/electricity-and-magnetismus/ohm-law-for-closed-circuit");
        namesList.push("Ohm's law for closed circuit");

        linksList.push("/physics/electricity-and-magnetismus/electrical-conductance");
        namesList.push("Electrical conductance");

        linksList.push("/physics/electricity-and-magnetismus/electrical-resistivity");
        namesList.push("Electrical resistivity");

        linksList.push("/physics/electricity-and-magnetismus/resistance-wire");
        namesList.push("Resistance wire");

        linksList.push("/physics/electricity-and-magnetismus/terminal-voltage");
        namesList.push("Terminal voltage");

        linksList.push("/physics/electricity-and-magnetismus/electrical-work-with-constant-current");
        namesList.push("Electrical work with constant current");

        linksList.push("/physics/electricity-and-magnetismus/electric-power");
        namesList.push("Electric power");

        linksList.push("/physics/electricity-and-magnetismus/faraday-law");
        namesList.push("Faraday's law");

        linksList.push("/physics/electricity-and-magnetismus/ampere-force-law");
        namesList.push("Ampère's force law");

        linksList.push("/physics/electricity-and-magnetismus/amperes-force-law-on-a-charged-particle");
        namesList.push("Ampère's force law on a charged particle");

        linksList.push("/physics/electricity-and-magnetismus/force-between-two-straight-parallel-conductors");
        namesList.push("Force between two straight parallel conductors");

        linksList.push("/physics/electricity-and-magnetismus/magnetic-field-intensity");
        namesList.push("Magnetic field intensity");

        linksList.push("/physics/electricity-and-magnetismus/magnetic-flux");
        namesList.push("Magnetic flux");

        linksList.push("/physics/electricity-and-magnetismus/faraday-law-of-induction");
        namesList.push("Faraday's law of induction");

        linksList.push("/physics/electricity-and-magnetismus/inductance");
        namesList.push("Inductance");

        linksList.push("/physics/electricity-and-magnetismus/electromotorive-force-in-solenoid");
        namesList.push("Electromotive force in solenoid");

        linksList.push("/physics/electricity-and-magnetismus/magnetic-energy-in-inductor");
        namesList.push("Magnetic energy in inductor");

        linksList.push("/physics/electricity-and-magnetismus/efficient-value-of-voltage");
        namesList.push("Effective value of voltage");

        linksList.push("/physics/electricity-and-magnetismus/efficient-value-of-current");
        namesList.push("Effective value of current");

        linksList.push("/physics/electricity-and-magnetismus/electrical-impedance");
        namesList.push("Electrical impedance");

        linksList.push("/physics/electricity-and-magnetismus/phase-shift");
        namesList.push("/phase shift");

        linksList.push("/physics/electricity-and-magnetismus/circuit-inductance");
        namesList.push("Circuit inductance");

        linksList.push("/physics/electricity-and-magnetismus/resonance-effect");
        namesList.push("Resonance effect");

        linksList.push("/physics/electricity-and-magnetismus/magnetic-induction-of-linear-wire");
        namesList.push("Magnetic induction of linear wire");

        linksList.push("/physics/electricity-and-magnetismus/magnetic-induction-of-circular-loop");
        namesList.push("Magnetic induction of circular loop");

        linksList.push("/physics/electricity-and-magnetismus/magnetic-induction-of-solenoid");
        namesList.push("Magnetic induction of solenoid");

        linksList.push("/physics/electricity-and-magnetismus/alternating-current-power-with-resistance");
        namesList.push("Alternating current power with resistance");

        linksList.push("/physics/electricity-and-magnetismus/alternating-current-power-with-impedance");
        namesList.push("Alternating current power with impedance");

        linksList.push("/physics/electricity-and-magnetismus/the-speed-of-propagation-of-electromagnetic-waves-in-vacuum");
        namesList.push("Velocity of propagation of electromagnetic waves in vacuum");

        linksList.push("/physics/electricity-and-magnetismus/the-speed-of-propagation-of-electromagnetic-waves-in-environment");
        namesList.push("Velocity of propagation of electromagnetic waves in environment");

        linksList.map((linkT, index) => {
            var item = {
                link: linkT,
                name: namesList[index]
            }

            itemsList.push(item);
        });

        return {
            title: titleString,
            items: itemsList
        }
    }

    getFormulaParams(formulaName) {
        switch (formulaName) {
            case "coulomb-law":
               return this.coulombLaw();
            case "electric-intensity-1":
               return this.electricIntensity1();
            case "electric-intensity-2":
               return this.electricIntensity2();
            case "charge-density":
               return this.charge_density();
            case "electric-potential-1":
               return this.electric_potential_1();
            case "electric-potential-2":
               return this.electric_potential_2();
            case "electrical-work":
               return this.electrical_work();
            case "capacitance-of-conductor":
               return this.capacitance_of_conductor();
            case "capacitance-of-capacitor":
               return this.capacitance_of_capacitor();
            case "work-of-capacitor":
               return this.work_of_capacitor();
            case "electric-current":
               return this.electric_current();
            case "ohms-law":
               return this.ohms_law();
            case "ohm-law-for-closed-circuit":
               return this.ohm_law_for_closed_circuit();
            case "electrical-conductance":
               return this.electrical_conductance();
            case "electrical-resistivity":
               return this.electrical_resistivity();
            case "resistance-wire":
               return this.resistance_wire();
            case "terminal-voltage":
               return this.terminal_voltage();
            case "electrical-work-with-constant-current":
               return this.electrical_work_with_constant_current();
            case "electric-power":
               return this.electric_power();
            case "faraday-law":
               return this.faraday_law();
            case "ampere-force-law":
               return this.ampere_force_law();
            case "amperes-force-law-on-a-charged-particle":
               return this.amperes_force_law_on_a_charged_particle();
            case "force-between-two-straight-parallel-conductors":
               return this.force_between_two_straight_parallel_conductors();
            case "magnetic-field-intensity":
               return this.magnetic_field_intensity();
            case "magnetic-flux":
               return this.magnetic_flux();
            case "faraday-law-of-induction":
               return this.faraday_law_of_induction();
            case "inductance":
               return this.inductance();
            case "electromotorive-force-in-solenoid":
               return this.electromotorive_force_in_solenoid();
            case "magnetic-energy-in-inductor":
               return this.magnetic_energy_in_inductor();
            case "efficient-value-of-voltage":
               return this.efficient_value_of_voltage();
            case "efficient-value-of-current":
               return this.efficient_value_of_current();
            case "electrical-impedance":
               return this.electrical_impedance();
            case "phase-shift":
               return this.phase_shift();
            case "circuit-inductance":
               return this.circuit_inductance();
            case "resonance-effect":
               return this.resonance_effect();
            case "magnetic-induction-of-linear-wire":
               return this.magnetic_induction_of_linear_wire();
            case "magnetic-induction-of-circular-loop":
               return this.magnetic_induction_of_circular_loop();
            case "magnetic-induction-of-solenoid":
               return this.magnetic_induction_of_solenoid();
            case "alternating-current-power-with-resistance":
               return this.alternating_current_power_with_resistance();
            case "alternating-current-power-with-impedance":
               return this.alternating_current_power_with_impedance();
            case "the-speed-of-propagation-of-electromagnetic-waves-in-vacuum":
               return this.the_speed_of_propagation_of_electromagnetic_waves_in_vacuum();
            case "the-speed-of-propagation-of-electromagnetic-waves-in-environment":
               return this.the_speed_of_propagation_of_electromagnetic_waves_in_environment();
        }
    }

    coulombLaw() {
        return {
            calculatorType: "e1E1D4PIe2Me3e4de6p2",
            imagePath: "coulomb_law",
            title: Strings.coulombs_law(),
            description: Strings.coulombs_law_description(),
            element1: "F",
            baseUnit1: "N",
            element2: "ε",
            baseUnit2: "F/m",
            element3: "Q1",
            baseUnit3: "C",
            element4: "Q2",
            baseUnit4: "C",
            element5: "r",
            baseUnit5: "m",
            baseUnit6: null,
            element6: null
        }
    }

    electricIntensity1() {
        return {
            calculatorType: "e1Ee2De3",
            imagePath: "electric_intensity_1",
            title: Strings.electric_intensity1(),
            description: Strings.electric_intensity1_description(),
            element1: "E",
            baseUnit1: "N/C",
            element2: "F",
            baseUnit2: "N",
            element3: "Q",
            baseUnit3: "C",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    electricIntensity2() {
        return {
            calculatorType: "e1E1D4PIe2Me3de4P2",
            imagePath: "electric_intensity_2",
            title: Strings.electric_intensity2(),
            description: Strings.electric_intensity2_description(),
            element1: "E",
            baseUnit1: "N/C",
            element2: "ε",
            baseUnit2: "F/m",
            element3: "Q",
            baseUnit3: "C",
            element4: "r",
            baseUnit4: "m",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    charge_density() {
        return {
            calculatorType: "e1Ee2De3",
            imagePath: "charge_density",
            title: Strings.charge_density(),
            description: Strings.charge_density_description(),
            element1: "σ",
            baseUnit1: "C/m^2",
            element2: "Q",
            baseUnit2: "C",
            element3: "S",
            baseUnit3: "m^2",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    electric_potential_1() {
        return {
            calculatorType: "e1Ee2De3",
            imagePath: "electric_potential_1",
            title: Strings.electric_potential1(),
            description: Strings.electric_potential1_description(),
            element1: "φ",
            baseUnit1: "V",
            element2: "E",
            baseUnit2: "N/C",
            element3: "Q",
            baseUnit3: "C",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    electric_potential_2() {
        return {
            calculatorType: "e1Ee2De3",
            imagePath: "electric_potential_2",
            title: Strings.electric_potential2(),
            description: Strings.electric_potential2_description(),
            element1: "φ",
            baseUnit1: "V",
            element2: "W",
            baseUnit2: "N/C",
            element3: "Q",
            baseUnit3: "C",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    electrical_work() {
        return {
            calculatorType: "e1Ee2Me3",
            imagePath: "electrical_work",
            title: Strings.electrical_work(),
            description: Strings.electrical_work_description(),
            element1: "W",
            baseUnit1: "J",
            element2: "Q",
            baseUnit2: "C",
            element3: "U",
            baseUnit3: "V",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    capacitance_of_conductor() {
        return {
            calculatorType: "e1Ee2De3",
            imagePath: "capacitance_of_conductor",
            title: Strings.capacitance_of_conductor(),
            description: Strings.capacitance_of_conductor_description(),
            element1: "C",
            baseUnit1: "F",
            element2: "Q",
            baseUnit2: "C",
            element3: "U",
            baseUnit3: "V",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    capacitance_of_capacitor() {
        return {
            calculatorType: "e1Ee2e3De4",
            imagePath: "capacitance_of_capacitor",
            title: Strings.capacitance_of_capacitor(),
            description: Strings.capacitance_of_capacitor_description(),
            element1: "C",
            baseUnit1: "F",
            element2: "ε",
            baseUnit2: "F/m",
            element3: "S",
            baseUnit3: "m^2",
            element4: "d",
            baseUnit4: "m",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    work_of_capacitor() {
        return {
            calculatorType: "e1E05e2Me3P2",
            imagePath: "work_of_capacitor",
            title: Strings.work_of_capacitor(),
            description: Strings.work_of_capacitor_description(),
            element1: "W",
            baseUnit1: "J",
            element2: "C",
            baseUnit2: "F",
            element3: "U",
            baseUnit3: "V",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    electric_current() {
        return {
            calculatorType: "e1Ee2De3",
            imagePath: "electric_current",
            title: Strings.electric_current(),
            description: Strings.electric_current_description(),
            element1: "I",
            baseUnit1: "A",
            element2: "Q",
            baseUnit2: "C",
            element3: "t",
            baseUnit3: "s",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    ohms_law() {
        return {
            calculatorType: "e1Ee2De3",
            imagePath: "ohm_law",
            title: Strings.ohms_law(),
            description: Strings.ohms_law_description(),
            element1: "I",
            baseUnit1: "A",
            element2: "U",
            baseUnit2: "V",
            element3: "R",
            baseUnit3: "Ω",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    ohm_law_for_closed_circuit() {
        return {
            calculatorType: "e1Ee2De3se4",
            imagePath: "ohm_law_for_closed_circuit",
            title: Strings.ohms_law_for_closed_circuit(),
            description: Strings.ohms_law_for_closed_circuit_description(),
            element1: "I",
            baseUnit1: "A",
            element2: "U",
            baseUnit2: "V",
            element3: "R1",
            baseUnit3: "Ω",
            element4: "R",
            baseUnit4: "Ω",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    electrical_conductance() {
        return {
            calculatorType: "e1E1De2",
            imagePath: "electrical_conductance",
            title: Strings.conductance(),
            description: Strings.conductance_description(),
            element1: "G",
            baseUnit1: "Ω^-1",
            element2: "R",
            baseUnit2: "Ω",
            element3: null,
            baseUnit3: null,
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    electrical_resistivity() {
        return {
            calculatorType: "e1Ee2e3De4",
            imagePath: "electrical_resistivity",
            title: Strings.electrical_resistivity(),
            description: Strings.electrical_resistivity_description(),
            element1: "ρ",
            baseUnit1: "Ωm",
            element2: "R",
            baseUnit2: "Ω",
            element3: "S",
            baseUnit3: "m^2",
            element4: "l",
            baseUnit4: "m",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    resistance_wire() {
        return {
            calculatorType: "e1E4e2dPIMe3de4P2",
            imagePath: "resistance_wire",
            title: Strings.resistance_wire(),
            description: Strings.resistance_wire_description(),
            element1: "R",
            baseUnit1: "Ω",
            element2: "ρ",
            baseUnit2: "Ωm",
            element3: "l",
            baseUnit3: "m",
            element4: "d",
            baseUnit4: "m",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    terminal_voltage() {
        return {
            calculatorType: "e1Ee2se3e4",
            imagePath: "terminal_voltage",
            title: Strings.terminal_voltage,
            description: Strings.terminal_voltage_description,
            element1: "U",
            baseUnit1: "V",
            element2: "Ue",
            baseUnit2: "V",
            element3: "R",
            baseUnit3: "Ω",
            element4: "I",
            baseUnit4: "A",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    electrical_work_with_constant_current() {
        return {
            calculatorType: "e1Ee2e3e4",
            imagePath: "electrical_work_with_constant_current",
            title: Strings.electrical_work_with_constant_current(),
            description: Strings.electrical_work_with_constant_current_description(),
            element1: "W",
            baseUnit1: "J",
            element2: "U",
            baseUnit2: "V",
            element3: "I",
            baseUnit3: "A",
            element4: "t",
            baseUnit4: "s",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    electric_power() {
        return {
            calculatorType: "e1Ee2Me3",
            imagePath: "electric_power",
            title: Strings.electric_power(),
            description: Strings.electric_power_description(),
            element1: "P",
            baseUnit1: "W",
            element2: "U",
            baseUnit2: "V",
            element3: "I",
            baseUnit3: "A",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    faraday_law() {
        return {
            calculatorType: "e1Ee2Me3",
            imagePath: "faraday_law",
            title: Strings.faradays_law(),
            description: Strings.faradays_law_description(),
            element1: "m",
            baseUnit1: "kg",
            element2: "A",
            baseUnit2: "kg/C",
            element3: "Q",
            baseUnit3: "C",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    ampere_force_law() {
        return {
            calculatorType: "e1Ee2e3e4sine5",
            imagePath: "ampere_force_law",
            title: Strings.ampères_force_law(),
            description: Strings.ampères_force_law_description(),
            element1: "F",
            baseUnit1: "N",
            element2: "B",
            baseUnit2: "T",
            element3: "I",
            baseUnit3: "A",
            element4: "l",
            baseUnit4: "m",
            element5: "α",
            baseUnit5: "°",
            baseUnit6: null,
            element6: null
        }
    }

    amperes_force_law_on_a_charged_particle() {
        return {
            calculatorType: "e1Ee2e3e4sine5",
            imagePath: "ampere_force_law_on__",
            title: Strings.ampères_force_law_on_a_charged_particle(),
            description: Strings.ampères_force_law_on_a_charged_particle_description(),
            element1: "F",
            baseUnit1: "N",
            element2: "Q",
            baseUnit2: "C",
            element3: "v",
            baseUnit3: "m/s",
            element4: "B",
            baseUnit4: "T",
            element5: "α",
            baseUnit5: "°",
            baseUnit6: null,
            element6: null
        }
    }

    force_between_two_straight_parallel_conductors() {
        return {
            calculatorType: "e1Ee2D2PIMe3e4e5de6",
            imagePath: "force_between_two_straight_parallel_conductors",
            title: Strings.force_between_two_straight_parallel_conductors(),
            description: Strings.force_between_two_straight_parallel_conductors_description(),
            element1: "F",
            baseUnit1: "N",
            element2: "μ",
            baseUnit2: "H/m",
            element3: "I1",
            baseUnit3: "A",
            element4: "I2",
            baseUnit4: "A",
            element5: "l",
            baseUnit5: "m",
            element6: "d",
            baseUnit6: "m",
        }
    }

    magnetic_field_intensity() {
        return {
            calculatorType: "e1Ee2De3",
            imagePath: "magnetic_field_intensity",
            title: Strings.magnetic_field_intensity(),
            description: Strings.magnetic_field_intensity_description(),
            element1: "H",
            baseUnit1: "A/m",
            element2: "B",
            baseUnit2: "T",
            element3: "μ",
            baseUnit3: "H/m",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    magnetic_flux() {
        return {
            calculatorType: "e1Ee2Me3Mcose4",
            imagePath: "magnetic_flux",
            title: Strings.magnetic_flux(),
            description: Strings.magnetic_flux_description(),
            element1: "Φ",
            baseUnit1: "Wb",
            element2: "B",
            baseUnit2: "T",
            element3: "S",
            baseUnit3: "m^2",
            element4: "α",
            baseUnit4: "°",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    faraday_law_of_induction() {
        return {
            calculatorType: "e1EMe2de3",
            imagePath: "faraday_law_of_induction",
            title: Strings.faradays_law_of_induction(),
            description: Strings.faradays_law_of_induction_description(),
            element1: "U",
            baseUnit1: "V",
            element2: "ΔΦ",
            baseUnit2: "Wb",
            element3: "Δt",
            baseUnit3: "s",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    inductance() {
        return {
            calculatorType: "e1Ee2De3",
            imagePath: "inductance",
            title: Strings.inductance(),
            description: Strings.inductance_description(),
            element1: "L",
            baseUnit1: "H",
            element2: "Φ",
            baseUnit2: "Wb",
            element3: "I",
            baseUnit3: "A",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    electromotorive_force_in_solenoid() {
        return {
            calculatorType: "e1EMe2e3de4",
            imagePath: "electromotorive_force_in_solenoid",
            title: Strings.electromotive_force_in_solenoid(),
            description: Strings.electromotive_force_in_solenoid_description(),
            element1: "U",
            baseUnit1: "V",
            element2: "L",
            baseUnit2: "H",
            element3: "ΔI",
            baseUnit3: "A",
            element4: "Δt",
            baseUnit4: "s",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    magnetic_energy_in_inductor() {
        return {
            calculatorType: "e1E05e2Me3P2",
            imagePath: "magnetic_energy_in_inductor",
            title: Strings.magnetic_energy_in_inductor(),
            description: Strings.magnetic_energy_in_inductor_description(),
            element1: "E",
            baseUnit1: "J",
            element2: "L",
            baseUnit2: "H",
            element3: "I",
            baseUnit3: "A",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    efficient_value_of_voltage() {
        return {
            calculatorType: "e1Ee2DSQRT2",
            imagePath: "efficient_value_of_voltage",
            title: Strings.effective_value_of_voltage(),
            description: Strings.effective_value_of_voltage_description(),
            element1: "U",
            baseUnit1: "V",
            element2: "Um",
            baseUnit2: "V",
            element3: null,
            baseUnit3: null,
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    efficient_value_of_current() {
        return {
            calculatorType: "e1Ee2DSQRT2",
            imagePath: "efficient_value_of_current",
            title: Strings.effective_value_of_current(),
            description: Strings.effective_value_of_current_description(),
            element1: "I",
            baseUnit1: "A",
            element2: "Im",
            baseUnit2: "A",
            element3: null,
            baseUnit3: null,
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    electrical_impedance() {
        return {
            calculatorType: "e1ESQRTe2p2ae3p2",
            imagePath: "electrical_impedance",
            title: Strings.electrical_impedance(),
            description: Strings.electrical_impedance_description(),
            element1: "Z",
            baseUnit1: "Ω",
            element2: "R",
            baseUnit2: "Ω",
            element3: "X",
            baseUnit3: "Ω",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    phase_shift() {
        return {
            calculatorType: "tge1Ee2De3",
            imagePath: "/phase_shift",
            title: Strings.phase_shift(),
            description: Strings.phase_shift_description(),
            element1: "φ",
            baseUnit1: "°",
            element2: "X",
            baseUnit2: "Ω",
            element3: "R",
            baseUnit3: "Ω",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    circuit_inductance() {
        return {
            calculatorType: "e1Ee2Me3",
            imagePath: "circuit_inductance",
            title: Strings.circuit_inductance(),
            description: Strings.circuit_inductance_description(),
            element1: "X",
            baseUnit1: "Ω",
            element2: "ω",
            baseUnit2: "s^-1",
            element3: "L",
            baseUnit3: "H",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    resonance_effect() {
        return {
            calculatorType: "e1E1DSQRTe2e3",
            imagePath: "resonance_effect",
            title: Strings.resonance_effect(),
            description: Strings.resonance_effect_description(),
            element1: "ω",
            baseUnit1: "s^-1",
            element2: "L",
            baseUnit2: "H",
            element3: "C",
            baseUnit3: "F",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    magnetic_induction_of_linear_wire() {
        return {
            calculatorType: "e1Ee2d2PIMe3de4",
            title: Strings.magnetic_induction_of_linear_wire(),
            description: Strings._of_linear_wire_description(),
            element1: "B",
            baseUnit1: "T",
            element2: "μ",
            baseUnit2: "H/m",
            element3: "I",
            baseUnit3: "A",
            element4: "d",
            baseUnit4: "m",
            imagePath: "magnetic_induction_of_linear_wire",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    magnetic_induction_of_circular_loop() {
        return {
            calculatorType: "e1Ee2e3d2e4",
            title: Strings.magnetic_induction_of_circular_loop(),
            description: Strings._of_circular_loop_description(),
            element1: "B",
            baseUnit1: "T",
            element2: "μ",
            baseUnit2: "H/m",
            element3: "I",
            baseUnit3: "A",
            element4: "r",
            baseUnit4: "m",
            imagePath: "magnetic_induction_of_circular_loop",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    magnetic_induction_of_solenoid() {
        return {
            calculatorType: "e1Ee2e3e4de5",
            title: Strings.magnetic_induction_of_solenoid(),
            description: Strings._of_solenoid_description(),
            element1: "B",
            baseUnit1: "T",
            element2: "μ",
            baseUnit2: "H/m",
            element3: "N",
            baseUnit3: " ",
            element4: "I",
            baseUnit4: "A",
            element5: "l",
            baseUnit5: "m",
            imagePath: "magnetic_induction_of_solenoid",
            baseUnit6: null,
            element6: null
        }
    }

    alternating_current_power_with_resistance() {
        return {
            calculatorType: "e1Ee2Me3",
            title: Strings.alternating_current_power_with_resistance(),
            description: Strings._with_resistance_description(),
            element1: "P",
            baseUnit1: "W",
            element2: "U",
            baseUnit2: "V",
            element3: "I",
            baseUnit3: "A",
            imagePath: "alternating_current_power_with_resistance",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    alternating_current_power_with_impedance() {
        return {

            calculatorType: "e1Ee2Me3Mcose4",
            title: Strings.alternating_current_power_with_impedance(),
            description: Strings._with_impedance_description(),
            element1: "P",
            baseUnit1: "W",
            element2: "U",
            baseUnit2: "V",
            element3: "I",
            baseUnit3: "A",
            element4: "α",
            baseUnit4: "°",
            imagePath: "alternating_current_power_with_impedance",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    the_speed_of_propagation_of_electromagnetic_waves_in_vacuum() {
        return {
            calculatorType: "e1E1DSQRTe2e3",
            title: Strings.the_speed_of_propagation_of_electromagnetic_waves_in_vacuum(),
            description: Strings._in_vacuum_description(),
            element1: "c",
            baseUnit1: "m/s",
            element2: "ε",
            baseUnit2: "F/m",
            element3: "μ",
            baseUnit3: "H/m",
            imagePath: "velocity_propagation_in_vacuum",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    the_speed_of_propagation_of_electromagnetic_waves_in_environment() {
        return {
            calculatorType: "e1Ee2DSQRTe3e4",
            title: Strings.the_speed_of_propagation_of_electromagnetic_waves_in_environment(),
            description: Strings._in_environment_description(),
            element1: "c",
            baseUnit1: "m/s",
            element2: "v",
            baseUnit2: "m/s",
            element3: "ε",
            baseUnit3: "F/m",
            element4: "μ",
            baseUnit4: "H/m",
            imagePath: "velocity_propagation_in_environment",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }
}

export default new ElectricityAndMagnetismus();
