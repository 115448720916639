import React, { Component } from "react";
import { Routes, Route, Link } from "react-router-dom";
import ResizeObserver from "rc-resize-observer";
import "./css/App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import List from "./components/list.component";
import Main from "./components/main.component";
import Solver from "./components/solver.component";

import imgIconHeader from "./icons/icon.png";
import imgAndroid from "./icons/android.png";
import imgAbout from "./icons/about.svg";
import Footer from "./components/footer.component";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footerClassName: "footer footer_fixed",
      oldHeightOfGrid: 0,
      isBackButtonVisible: false,
      currentPath: "",
      pageTitle: "",
    };

    this.goBack = this.goBack.bind(this);
    this.setPageTitle = this.setPageTitle.bind(this);
    this.updateFooterPosition = this.updateFooterPosition.bind(this);
  }

  goBack() {
    const currentPathArray = this.state.currentPath.split("/");
    currentPathArray.pop();

  }

  componentDidMount() {
    this.updateFooterPosition();
    window.addEventListener("resize", this.updateFooterPosition);
  }

  updateFooterPosition() {
    if (
      this.state.oldHeightOfGrid !==
      document.getElementById("page_content_wrapper").clientHeight
    ) {
      const heightOfGrid = document.getElementById(
        "page_content_wrapper"
      ).clientHeight;
      const heightOfWindow = window.innerHeight;
      var newFooterClassName = "footer footer_";
      if (heightOfWindow < heightOfGrid + 80) {
        newFooterClassName += "bottom";
      } else {
        newFooterClassName += "fixed";
      }

      this.setState({
        oldHeightOfGrid: heightOfGrid,
        footerClassName: newFooterClassName,
      });
    }
  }

  componentDidUpdate() {
    const path = window.location.pathname;
    if (this.state.currentPath !== path) {
      const currentPathArray = path.split("/");
      currentPathArray.pop();
      this.setState({
        isBackButtonVisible: path !== "/",
        currentPath: path,
        pathBack: currentPathArray.join("/")
      });
    }
  }

  setPageTitle(pageTitle) {
    this.setState({
      pageTitle: pageTitle,
    });
  }

  render() {
    return (
      <div>
        <header>
          {this.state.isBackButtonVisible ? (
            <div>
              <Link
                className="btn_back"
                to={this.state.pathBack}
                title="Go back"
              />
              <h1 className="page_title">{this.state.pageTitle}</h1>
            </div>
          ) : ( 
            <div>
              <div className="title">
                <img
                  src={imgIconHeader}
                  alt="Formula Solver"
                  height="40"
                  width="40"
                />
                <h1>Formula Solver</h1>
              </div>

              <nav>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.davidsimak.dev/prjects/formula-solver"
                    >
                      <img
                        title="About app"
                        src={imgAbout}
                        alt="About"
                        height="40"
                        width="40"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://play.google.com/store/apps/details?id=cz.david_simak.formula_solver"
                    >
                      <img
                        title="Android App"
                        src={imgAndroid}
                        alt="Android"
                        height="50"
                        width="50"
                      />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </header>
        <ResizeObserver onResize={this.updateFooterPosition}>
          <div id="page_content_wrapper" className="container mt-3">
            <Routes>
              <Route exact path="/" element={<Main />} />
              <Route
                exact
                path="/chemistry"
                element={<List setPageTitle={this.setPageTitle} />}
              />
              <Route
                exact
                path="/chemistry/:formula"
                element={<Solver setPageTitle={this.setPageTitle} />}
              />
              <Route
                exact
                path="/physics"
                element={<List setPageTitle={this.setPageTitle} />}
              />
              <Route
                exact
                path="/physics/:category"
                element={<List setPageTitle={this.setPageTitle} />}
              />
              <Route
                exact
                path="/physics/:category/:formula"
                element={<Solver setPageTitle={this.setPageTitle} />}
              />
              <Route path="*" element={<External />} />
            </Routes>
          </div>
        </ResizeObserver>
        <Footer customClass={this.state.footerClassName} />
      </div>
    );
  }
}

function External() {
  window.location.href = "https://www.davidsimak.dev/error/not-found";
  return null;
}

export default App;
