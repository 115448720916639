import Strings from "./strings.helper";

class QuantumPhysics {
    getFormulas() {
        const titleString = "Quantum physics";
        var namesList = [];
        var linksList = [];
        var itemsList = [];

        linksList.push("/physics/quantum-physics/photon-energy");
        namesList.push("Photon energy");

        linksList.push("/physics/quantum-physics/photon-momentum");
        namesList.push("Photon momentum");

        linksList.push("/physics/quantum-physics/photon-mass");
        namesList.push("Photon mass");

        linksList.push("/physics/quantum-physics/einstein-equation");
        namesList.push("Einstein's equation");

        linksList.push("/physics/quantum-physics/De-Broglie-wavelength-relationship");
        namesList.push("De Broglie wavelength relationship");

        linksList.push("/physics/quantum-physics/binding-energy");
        namesList.push("Binding energy");

        linksList.push("/physics/quantum-physics/nuclear-binding-energy");
        namesList.push("Nuclear binding energy");

        linksList.push("/physics/quantum-physics/expansion-velocity-of-galaxies");
        namesList.push("Expansion velocity of galaxies");

        linksList.push("/physics/quantum-physics/cutoff-wavelength");
        namesList.push("Cutoff wavelength of X-rays");

        linksList.map((linkT, index) => {
            var item = {
                link: linkT,
                name: namesList[index]
            }

            itemsList.push(item);
        });

        return {
            title: titleString,
            items: itemsList
        }
    }

    getFormulaParams(formulaName) {
        switch (formulaName) {
            case "photon-energy":
               return this.photon_energy();
            case "photon-momentum":
               return this.photon_momentum();
            case "photon-mass":
               return this.photon_mass();
            case "einstein-equation":
               return this.einstein_equation();
            case "De-Broglie-wavelength-relationship":
               return this.De_Broglie_wavelength_relationship();
            case "binding-energy":
               return this.binding_energy();
            case "nuclear-binding-energy":
               return this.nuclear_binding_energy();
            case "expansion-velocity-of-galaxies":
               return this.expansion_velocity_of_galaxies();
            case "cutoff-wavelength":
               return this.cutoff_wavelength();
        }
    }

    photon_energy() {
        return {
            calculatorType: "e1Ee2e3",
            imagePath: "photon_energy",
            title: Strings.photon_energy(),
            description: Strings.photon_energy_description(),
            element1: "E",
            baseUnit1: "J",
            element2: "h",
            baseUnit2: "Js",
            element3: "f",
            baseUnit3: "Hz",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    photon_momentum() {
        return {
            calculatorType: "e1Ee2e3De4",
            imagePath: "photon_momentum",
            title: Strings.photon_momentum(),
            description: Strings.photon_momentum_description(),
            element1: "p",
            baseUnit1: "kgm/s",
            element2: "h",
            baseUnit2: "Js",
            element3: "f",
            baseUnit3: "Hz",
            element4: "c",
            baseUnit4: "m/s",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    photon_mass() {
        return {
            calculatorType: "e1Ee2De3e4",
            imagePath: "photon_mass",
            title: Strings.photon_mass(),
            description: Strings.photon_weight_description(),
            element1: "m",
            baseUnit1: "kg",
            element2: "h",
            baseUnit2: "Js",
            element3: "λ",
            baseUnit3: "m",
            element4: "c",
            baseUnit4: "m/s",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    einstein_equation() {
        return {
            calculatorType: "e1e2Ee3A05e4e5p2",
            imagePath: "einstein_equation",
            title: Strings.einstein_equation(),
            description: Strings.einstein_equation_description(),
            element1: "h",
            baseUnit1: "Js",
            element2: "f",
            baseUnit2: "Hz",
            element3: "Wv",
            baseUnit3: "J",
            element4: "m",
            baseUnit4: "kg",
            element5: "v",
            baseUnit5: "m/s",
            baseUnit6: null,
            element6: null
        }
    }

    De_Broglie_wavelength_relationship() {
        return {
            calculatorType: "e1Ee2De3e4",
            imagePath: "de_broglie___",
            title: Strings.De_Broglie_wavelength_relationship(),
            description: Strings.De_Broglie_wavelength_relationship_description(),
            element1: "λ",
            baseUnit1: "m",
            element2: "h",
            baseUnit2: "Js",
            element3: "m",
            baseUnit3: "kg",
            element4: "v",
            baseUnit4: "m/s",
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    binding_energy() {
        return {
            calculatorType: "e1Ee2e3p2",
            imagePath: "binding_energy",
            title: Strings.binding_energy(),
            description: Strings.binding_energy_description(),
            element1: "Wb",
            baseUnit1: "J",
            element2: "B",
            baseUnit2: "kg",
            element3: "c",
            baseUnit3: "m/s",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    nuclear_binding_energy() {
        return {
            calculatorType: "e1Ee2De3",
            imagePath: "nuclear_biding_energy",
            title: Strings.nuclear_binding_energy(),
            description: Strings.nuclear_binding_energy_description(),
            element1: "wb",
            baseUnit1: "J",
            element2: "Wb",
            baseUnit2: "J",
            element3: "A",
            baseUnit3: " ",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    expansion_velocity_of_galaxies() {
        return {
            calculatorType: "e1Ee2e3",
            imagePath: "expansion_velocity_of_galaxies",
            title: Strings.expansion_velocity_of_galaxies(),
            description: Strings.expansion_velocity_of_galaxies_description(),
            element1: "v",
            baseUnit1: "m/s",
            element2: "H",
            baseUnit2: "km/sMPC",
            element3: "r",
            baseUnit3: "m",
            element4: null,
            baseUnit4: null,
            element5: null,
            baseUnit5: null,
            baseUnit6: null,
            element6: null
        }
    }

    cutoff_wavelength() {
        return {
            calculatorType: "e1Ee2e3De4e5",
            imagePath: "cutoff_wavelength",
            title: Strings.cutoff_wavelength_of_X_rays(),
            description: Strings.cutoff_wavelength_of_X_rays_description(),
            element1: "λ",
            baseUnit1: "m",
            element2: "h",
            baseUnit2: "Js",
            element3: "c",
            baseUnit3: "m/s",
            element4: "e",
            baseUnit4: "C",
            element5: "U",
            baseUnit5: "V",
            baseUnit6: null,
            element6: null
        }
    }
}

export default new QuantumPhysics();
