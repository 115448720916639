import React, { Component } from "react";
import { Link } from "react-router-dom";
import reportVisitService from "../reporting-visit/report-visit.service";

export default class Main extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title =
      "Formula Solver | Physical and chemical online calculation";
    reportVisitService.report(window.location.pathname, document.title);
  }

  render() {
    return (
      <div className="main">
        <div className="div_physics">
          <h1 className="title_list">Physics</h1>
          <Link to="physics/electricity-and-magnetismus">
            Electricity and magnetism
          </Link>
          <Link to="physics/hydromechanics">Hydromechanics</Link>
          <Link to="physics/mechanical-vibration-waves-acoustic">
            Mechanical vibration, waves and acoustic
          </Link>
          <Link to="physics/mechanics">Mechanics</Link>
          <Link to="physics/molecular-physics-thermodynamic">
            Molecular physics and thermodynamic
          </Link>
          <Link to="physics/optics">Optics</Link>
          <Link to="physics/quantum-physics">Quantum physics</Link>
          <Link to="physics/special-theory-of-relativity">
            The special theory of relativity
          </Link>
        </div>

        <div className="div_chemistry">
          <h1 className="title_list">Chemistry</h1>
          <Link to="chemistry/mass-fraction">Mass fraction</Link>
          <Link to="chemistry/molecular-weight-and-amount-of-substance">
            Molecular weight and amount of substance
          </Link>
          <Link to="chemistry/concentration">Concentration</Link>
          <Link to="chemistry/molar-volume">Molar volume</Link>
          <Link to="chemistry/substance-amount">Substance amount</Link>
          <Link to="chemistry/substance-amount-with-Avogadros-number">
            Substance amount with Avogadro´s number
          </Link>
          <Link to="chemistry/mixing-equation">Mixing equation</Link>
          <Link to="chemistry/ideal-gas-law">Ideal gas law</Link>
        </div>
      </div>
    );
  }
}
