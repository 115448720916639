import http from "../http-common";

class SolverService {
  solveChemistry(data) {
    return http.post("/chemistry", data);
  }

  solveElectricityAndMagnetism(data) {
    return http.post("/electricity-and-magnetism", data);
  }

  solveHydromechanics(data) {
    return http.post("/hydromechanics", data);
  }

  solveMechanicalVibrationWavesAndAcoustic(data) {
    return http.post("/mechanical-vibration-waves-and-acoustic", data);
  }

  solveMechanics(data) {
    return http.post("/mechanics", data);
  }

  solveMolecularPhysicsAndThermodynamic(data) {
    return http.post("/molecular-physics-and-thermodynamic", data);
  }

  solveOptics(data) {
    return http.post("/optics", data);
  }

  solveQuantumPhysics(data) {
    return http.post("/quantum-physics", data);
  }

  solveTheSpecialTheoryOfRelativity(data) {
    return http.post("/the-special-theory-of-relativity", data);
  }
}

export default new SolverService();
